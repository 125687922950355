import React, { useState, useMemo, useCallback } from 'react';
import {
  IonFabList,
  IonFabButton,
  IonIcon,
  IonFab,
  IonInput,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonSkeletonText,
  IonTextarea,
  IonItem,
  IonLabel,
} from '@ionic/react';
import {
  ellipsisVerticalOutline,
  searchOutline,
  addOutline,
} from 'ionicons/icons';
import firebase from '../firebase';

import './GroupsTab.css';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import Layout from '../layouts/Layout';
import ActionModal from '../components/ActionModal';

import GetStartedCTA from '../components/GetStartedCTA';
import useGroupMembership from '../hooks/useGroupMembership';
import { addUserToGroup } from '../helpers/dataMutations';
import { IGroup } from '../../types/IGroup';
import { groupFromDoc } from '../helpers/dataMappers';

const GroupsTab: React.FC = () => {
  const [isJoinModalOpen, setIsJoinModalOpen] = useState<boolean>(false);
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState<boolean>(
    false,
  );

  const { groups, loading } = useGroupMembership();

  return (
    <Layout className="groups" pageName="View Groups">
      <IonToolbar>
        <IonTitle>Groups</IonTitle>
      </IonToolbar>

      {loading && (
        <>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </>
      )}

      {!loading && groups.length === 0 && (
        <IonCard style={{ marginTop: 100 }}>
          <GetStartedCTA text="Join or create a group to get started" />
        </IonCard>
      )}
      {groups.map((group) => (
        <IonCard key={group.id} routerLink={`/groups/${group.id}`}>
          <IonCardHeader>
            <IonCardTitle>{group.name}</IonCardTitle>
          </IonCardHeader>
        </IonCard>
      ))}

      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton>
          <IonIcon icon={ellipsisVerticalOutline} />
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton
            color="secondary"
            onClick={() => setIsAddGroupModalOpen(true)}
          >
            <IonIcon icon={addOutline} />
          </IonFabButton>
          <IonFabButton
            color="primary"
            onClick={() => setIsJoinModalOpen(true)}
          >
            <IonIcon icon={searchOutline} />
          </IonFabButton>
        </IonFabList>
      </IonFab>

      <JoinModal
        isOpen={isJoinModalOpen}
        onDismiss={() => setIsJoinModalOpen(false)}
      />

      <AddEditGroupModal
        isOpen={isAddGroupModalOpen}
        onDismiss={() => setIsAddGroupModalOpen(false)}
      />
    </Layout>
  );
};

type IJoinModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};
const JoinModal: React.FC<IJoinModalProps> = ({ isOpen, onDismiss }) => {
  const [code, setCode] = useState<string>('');
  const currentUser = useRequireCurrentUser();

  const codeIsValid = useMemo(() => code.length > 1, [code]);

  const handleJoin = async () => {
    // @ts-ignore
    await addUserToGroup(currentUser, { id: code });

    onDismiss();
  };
  return (
    <ActionModal
      modalName="Join Group"
      isOpen={isOpen}
      title="Join a Group"
      buttons={[
        {
          label: 'Join',
          onClick: handleJoin,
        },
        {
          label: 'Cancel',
          onClick: onDismiss,
        },
      ]}
      onDismiss={onDismiss}
      valid={codeIsValid}
    >
      <IonInput
        value={code}
        onIonChange={(e) => setCode(e.detail.value || '')}
        placeholder="Code"
      />
    </ActionModal>
  );
};

type IAddGroupModalProps = {
  isOpen: boolean;
  onDismiss: (e: any, group?: IGroup) => void;
  group?: IGroup;
};

export const AddEditGroupModal: React.FC<IAddGroupModalProps> = ({
  isOpen,
  onDismiss,
  group,
}) => {
  const currentUser = useRequireCurrentUser();
  const [name, setName] = useState<string>(group?.name || '');
  const [description, setDescription] = useState<string>(
    group?.description || '',
  );

  const handleAdd = useCallback(
    async (e: React.MouseEvent) => {
      let groupId: string | null = null;

      if (!group) {
        await firebase.firestore().runTransaction(async (tx) => {
          const groupDocRef = firebase.firestore().collection('groups').doc();

          const groupDoc = await tx.get(groupDocRef);

          groupId = groupDoc.id;

          tx.set(groupDoc.ref, {
            name,
            description,
            createdAt: firebase.firestore.Timestamp.now(),
            admins: [currentUser.id],
          });

          const memberOfGroupDoc = currentUser.ref
            .collection('memberOfGroups')
            .doc(groupDoc.id);

          tx.set(memberOfGroupDoc, {
            userId: currentUser.id,
            groupId: groupDocRef.id,
            joinedAt: new Date(),
          });
        });

        if (groupId) {
          const doc = await firebase
            .firestore()
            .collection('groups')
            .doc(groupId)
            .get();

          return onDismiss(e, groupFromDoc(doc));
        }
      } else {
        await group.ref.update({
          name,
          description,
        });

        return onDismiss(e, group);
      }
    },
    [currentUser, description, group, name, onDismiss],
  );

  return (
    <ActionModal
      title={`${group ? 'Update' : 'Add'} Group`}
      modalName={`${group ? 'Update' : 'Add'} Group`}
      isOpen={isOpen}
      buttons={[
        {
          label: group ? 'Save' : 'Add',
          onClick: handleAdd,
        },
        { label: 'Cancel' },
      ]}
      onDismiss={onDismiss}
    >
      <>
        <IonItem>
          <IonLabel position="floating">Name</IonLabel>
          <IonInput
            value={name}
            onIonChange={(e) => setName(e.detail.value || '')}
            placeholder="Name"
          />
        </IonItem>

        <IonItem>
          <IonLabel position="floating">Description</IonLabel>
          <IonTextarea
            value={description}
            onIonChange={(e) => setDescription(e.detail.value as string)}
          />
        </IonItem>
      </>
    </ActionModal>
  );
};

const SkeletonCard = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          <IonSkeletonText animated />{' '}
        </IonCardTitle>
      </IonCardHeader>
    </IonCard>
  );
};
export default GroupsTab;
