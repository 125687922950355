import { firestore } from '../firebase';
import { useState, useEffect } from 'react';
import { IGroup } from '../../types/IGroup';
import { groupFromDoc } from '../helpers/dataMappers';
import useTimedLoading from './useTimedLoading';

const useGroup = (id: string) => {
  const [group, setGroup] = useState<IGroup>();
  const { start: startLoading, clear: clearLoading, loading } = useTimedLoading(
    1500,
  );

  useEffect(() => {
    startLoading();
    return firestore
      .collection('groups')
      .doc(id)
      .onSnapshot((snap) => {
        clearLoading();
        setGroup(groupFromDoc(snap));
      });
  }, [clearLoading, id, startLoading]);

  return {
    group,
    loading,
  };
};

export default useGroup;
