import React, { useState } from 'react';
import { QuantityType } from '../../types/IShoppingList';
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonAlert,
} from '@ionic/react';
import { trashOutline, remove, add } from 'ionicons/icons';
import ActionModal from './ActionModal';
import { UNITS } from '../constants';

interface IEditableItem {
  id: string;
  name: string;
  quantity?: number;
  quantityType?: QuantityType;
  notes?: string | null;
}

interface IEditedItem {
  quantity: number;
  quantityType: QuantityType;
  notes?: string;
}
interface IProps {
  item: IEditableItem;
  title?: string;
  isOpen: boolean;
  onDismiss: () => void;
  onDeleteClick?: () => void;
  onUpdate: (changed: IEditedItem) => void;
}

const EditShoppingListItemModal: React.FC<IProps> = ({
  item,
  isOpen,
  title,
  onDismiss,
  onDeleteClick,
  onUpdate,
}) => {
  const [quantity, setQuantity] = useState<number>(item.quantity || 1);
  const [quantityType, setQuantityType] = useState<QuantityType>(
    item.quantityType || 'each',
  );
  const [notes, setNotes] = useState<string>(item.notes || '');
  const [isDeleteConfirmAlertOpen, setIsDeleteConfirmAlertOpen] = useState<
    boolean
  >(false);

  const handleUpdate = () => {
    onUpdate({ quantity, quantityType, notes });
    onDismiss();
  };

  const handleDelete = () => {
    if (onDeleteClick) {
      onDeleteClick();
    }

    onDismiss();
  };

  const handleConfirmDelete = () => setIsDeleteConfirmAlertOpen(true);

  const Footer = () => (
    <IonToolbar className="ion-text-center">
      <IonButtons>
        <IonButton color="danger" onClick={handleConfirmDelete}>
          <IonIcon icon={trashOutline} />
          Remove
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );

  return (
    <ActionModal
      modalName="Edit Shopping List Item"
      title={title || 'Update'}
      isOpen={true}
      onDismiss={onDismiss}
      buttons={[
        { label: 'Update', onClick: handleUpdate },
        { label: 'Cancel' },
      ]}
      footer={onDeleteClick ? <Footer /> : undefined}
    >
      <>
        <IonContent>
          <IonList lines="full">
            <IonItem lines="none">
              <IonLabel>Quantity</IonLabel>
            </IonItem>
            <IonItem>
              <IonItem slot="end">
                <IonButtons>
                  <IonButton
                    slot="start"
                    onClick={() =>
                      setQuantity(quantity === 0 ? 0 : quantity - 1)
                    }
                  >
                    <IonIcon icon={remove} />
                  </IonButton>
                </IonButtons>
                <IonInput
                  value={quantity || 1}
                  type="number"
                  style={{ textAlign: 'center' }}
                  readonly
                />
                <IonButtons slot="end">
                  <IonButton onClick={() => setQuantity(quantity + 1)}>
                    <IonIcon icon={add} />
                  </IonButton>
                </IonButtons>
                <IonSelect
                  value={quantityType}
                  slot="end"
                  onIonChange={(e) => setQuantityType(e.detail.value)}
                >
                  {UNITS.map((unit) => (
                    <IonSelectOption key={unit} value={unit}>
                      {unit}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonItem>

            <IonItem>
              <IonLabel position="floating">Notes</IonLabel>
              <IonTextarea
                value={notes}
                onIonChange={(e) => setNotes(e.detail.value || '')}
                debounce={500}
                spellcheck
                placeholder="Enter some notes to help out your shopper..."
              ></IonTextarea>
            </IonItem>
          </IonList>
        </IonContent>

        <IonAlert
          header="Remove?"
          isOpen={isDeleteConfirmAlertOpen}
          message={`Are you sure you want to remove ${item.name} from your list?`}
          buttons={[
            { text: 'Nevermind', role: 'cancel', cssClass: 'secondary' },
            {
              text: 'Remove',
              role: 'destructive',
              cssClass: 'danger',
              handler: handleDelete,
            },
          ]}
        />
      </>
    </ActionModal>
  );
};

export default EditShoppingListItemModal;
