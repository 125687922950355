import React, { useState, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonSplitPane,
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuToggle,
  IonTitle,
  IonLoading,
  IonToast,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  cartOutline,
  listOutline,
  peopleOutline,
  mapOutline,
  fastFoodOutline,
  closeOutline,
} from 'ionicons/icons';
import ShoppingListTab from './pages/ShoppingListsTab';
import ShoppingListPage from './pages/ViewShoppingList';
import GoShopTab from './pages/GoShopTab';
import GroupsTab from './pages/GroupsTab';
import SignIn from './pages/SignIn';
import GroupPage from './pages/ViewGroup';
import ProfilePage from './pages/Profile';
import OnboardingPage from './pages/Onboarding';
import DeliverGroceriesTab from './pages/DeliverGroceriesTab';
import HomePage from './pages/Home';
import {
  CurrentUserContext,
  useAuthenticateCurrentUser,
} from './hooks/useCurrentUser';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';
import CloudMessagingRequest from './components/CloudMessagingRequest';
import AppErrorBoundary from './components/AppErrorBoundary';
import { GlobalToastContext, IToast } from './hooks/useGlobalToast';
import {
  GroupMembershipContext,
  useInitializeGroupMembership,
} from './hooks/useGroupMembership';
import InvitePage from './pages/Invite';

const App: React.FC = () => {
  const {
    currentUser,
    loading,
    isAuthenticated,
  } = useAuthenticateCurrentUser();
  const [toast, setToast] = useState<IToast | null>(null);

  useEffect(() => {
    const redirectAfterSignIn = window.localStorage.getItem(
      'redirectAfterSignIn',
    );
    if (currentUser && redirectAfterSignIn) {
      window.localStorage.removeItem('redirectAfterSignIn');
      // @ts-ignore
      window.location = redirectAfterSignIn;
    }
  }, [currentUser]);

  if (loading) {
    return (
      <IonApp className="app">
        <div className="splash">
          <img src="/assets/logo.png" className="logo" alt="logo" />
        </div>
        <IonLoading isOpen={loading} showBackdrop={false} />
      </IonApp>
    );
  }

  return (
    <AppErrorBoundary>
      <GlobalToastContext.Provider value={{ toast, setToast }}>
        <CurrentUserContext.Provider value={currentUser}>
          <GroupMembershipContextProvider>
            <IonApp className="app">
              <CloudMessagingRequest />
              <IonReactRouter>
                <IonSplitPane contentId="main" when={false}>
                  <Menu />
                  <IonRouterOutlet id="main">
                    <AuthedRoute
                      key="/groceries"
                      path="/groceries"
                      component={GroceriesSection}
                      isAuthed={isAuthenticated}
                    />
                    <AuthedRoute
                      key="/groups"
                      path="/groups"
                      component={GroupsTab}
                      isAuthed={isAuthenticated}
                      exact
                    />
                    <AuthedRoute
                      path="/groups/:id"
                      component={GroupPage}
                      isAuthed={isAuthenticated}
                      exact
                    />
                    <AuthedRoute
                      key="/profile"
                      path="/profile"
                      component={ProfilePage}
                      isAuthed={isAuthenticated}
                      exact
                    />
                    <AuthedRoute
                      key="/welcome"
                      path="/welcome"
                      component={OnboardingPage}
                      isAuthed={isAuthenticated}
                      exact
                    />

                    <AuthedRoute
                      path="/"
                      component={HomePage}
                      isAuthed={isAuthenticated}
                      exact
                    />

                    <Route path="/invite/:code" component={InvitePage} exact />
                    <Route path="/sign-in" component={SignIn} exact />
                  </IonRouterOutlet>
                </IonSplitPane>
              </IonReactRouter>
              <IonToast
                isOpen={!!toast}
                {...toast}
                onDidDismiss={() => setToast(null)}
              />
            </IonApp>
          </GroupMembershipContextProvider>
        </CurrentUserContext.Provider>
      </GlobalToastContext.Provider>
    </AppErrorBoundary>
  );
};

const AuthedRoute: React.FC<RouteProps & { isAuthed: boolean | undefined }> = ({
  isAuthed,
  ...props
}) => {
  if (isAuthed === undefined) {
    return null;
  }

  if (isAuthed) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/sign-in" />;
  }
};

const Menu = () => {
  return (
    <IonMenu contentId="main" menuId="main">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Menu</IonTitle>
          <IonButtons slot="end">
            <IonMenuToggle menu="main">
              <IonButton>
                <IonIcon icon={closeOutline} slot="icon-only" />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonMenuToggle menu="main">
            <IonItem routerLink="/groceries/shopping-lists" detail={false}>
              <IonIcon slot="start" icon={fastFoodOutline} />
              <IonLabel>Groceries</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle menu="main">
            <IonItem routerLink="/groups" detail={false}>
              <IonIcon slot="start" icon={peopleOutline} />
              <IonLabel>Groups</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

/** Wrap the GroupMembershipContext.Provider in something that can use a hook to allow for currentUser hook to fire */
const GroupMembershipContextProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const groupMembership = useInitializeGroupMembership();

  return (
    <GroupMembershipContext.Provider value={groupMembership}>
      {children}
    </GroupMembershipContext.Provider>
  );
};

const GroceriesSection = () => {
  return (
    <IonTabs>
      <IonRouterOutlet id="grocieries-tab">
        <Route
          path="/groceries/shopping-lists"
          component={ShoppingListTab}
          exact
        />
        <Route
          path="/groceries/shopping-lists/:id"
          component={ShoppingListPage}
          exact
        />
        <Route path="/groceries/shop" component={GoShopTab} exact={true} />
        <Route
          path="/groceries/deliver"
          component={DeliverGroceriesTab}
          exact
        />
        <Route
          path="/groceries"
          render={() => <Redirect to="/groceries/shopping-lists" />}
          exact
        />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="lists" href="/groceries/shopping-lists">
          <IonIcon icon={listOutline} />
          <IonLabel>Plan</IonLabel>
        </IonTabButton>
        <IonTabButton tab="shop" href="/groceries/shop">
          <IonIcon icon={cartOutline} />
          <IonLabel>Go Shop</IonLabel>
        </IonTabButton>
        <IonTabButton tab="deliver" href="/groceries/deliver">
          <IonIcon icon={mapOutline} />
          <IonLabel>Deliver</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default App;
