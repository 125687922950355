import { IUser, IUserPrivate } from '../../types/IUser';
import {
  IShoppingList,
  IShoppingListItem,
  IShoppingListItemInfo,
} from '../../types/IShoppingList';
import spoonacularImageSrc from './spoonacularImageSrc';
import { IGroup } from '../../types/IGroup';
import { IUnifiedFoodItem } from '../hooks/useSpoonacular';

type FirebaseDocument = Pick<
  firebase.firestore.DocumentSnapshot,
  'id' | 'ref' | 'data'
>;

const dataFromDoc = <T extends object>(
  doc: FirebaseDocument,
  mapper: string[],
): T => {
  const data = doc.data();

  if (!data) {
    throw new Error('Invalid data in document');
  }

  if (Array.isArray(mapper)) {
    const obj: any = mapper.reduce(
      (acc, key) => ({
        ...acc,
        [key]: data[key],
      }),
      {
        id: doc.id,
        ref: doc.ref,
      },
    );

    return obj;
  } else {
    throw new Error('not implemented');
  }
};

const userFromDoc = (doc: FirebaseDocument): IUser => {
  return dataFromDoc(doc, ['name', 'photoURL', 'onboardingCompleted']);
};

const userPrivateFromDoc = (doc: FirebaseDocument): IUserPrivate => {
  return dataFromDoc(doc, ['email', 'fcmTokens']);
};

const shoppingListFromDoc = (doc: FirebaseDocument): IShoppingList =>
  dataFromDoc(doc, [
    'name',
    'status',
    'userId',
    'createdAt',
    'targetDate',
    'shoppingStatus',
    'visibleToGroupIds',
  ]);

const shoppingListItemFromDoc = (doc: FirebaseDocument): IShoppingListItem => {
  const data = dataFromDoc(doc, [
    'name',
    'image',
    'aisle',
    'userId',
    'quantity',
    'quantityType',
    'notes',
    'refId',
    'refSource',
    'shoppingListId',
    'type',
    'clonedFromItemId',
    'clonedFromItemPath',
    'clonedByUserIds',
    'deletedOn',
    'dibbedBy',
    'dibbedOn',
  ]) as IShoppingListItem;

  const _data = {
    ...data,
    originalItemId: data.clonedFromItemId || doc.id,
    originalItemPath: data.clonedFromItemPath || doc.ref.path,
    image: spoonacularImageSrc(data.image),
  };

  return _data;
};

const shoppingListItemInfoFromDoc = (
  doc: FirebaseDocument,
): IShoppingListItemInfo =>
  dataFromDoc(doc, ['userId', 'shoppingListItemId', 'hidden']);

const groupFromDoc = (doc: FirebaseDocument): IGroup =>
  dataFromDoc(doc, ['name', 'description', 'admins']);

const foodItemFromDoc = (doc: FirebaseDocument): IUnifiedFoodItem =>
  dataFromDoc(doc, ['name', 'aisle', 'type', 'image', 'source']);

export {
  userFromDoc,
  shoppingListFromDoc,
  shoppingListItemFromDoc,
  shoppingListItemInfoFromDoc,
  groupFromDoc,
  foodItemFromDoc,
  userPrivateFromDoc,
};
