import { analytics } from '../firebase';
import { useEffect, useCallback } from 'react';

interface IOptions {
  screenName?: string;
}

const useAnalytics = ({ screenName }: IOptions = {}) => {
  const logScreenView = useCallback((_screenName: string) => {
    analytics.setCurrentScreen(_screenName);
    analytics.logEvent('screen_view', {
      screen_name: _screenName,
      app_name: 'Backyard Collective',
    });
  }, []);

  useEffect(() => {
    if (screenName) {
      logScreenView(screenName);
    }
  }, [logScreenView, screenName]);

  return {
    logScreenView,
    logEvent: analytics.logEvent.bind(analytics),
  };
};

export default useAnalytics;
