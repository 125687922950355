import firebase, { messaging } from '../firebase';
import useCurrentUser from './useCurrentUser';
import { useEffect, useCallback, useState } from 'react';
import usePersistedState from './usePersistedState';

const useCloudMessaging = () => {
  const currentUser = useCurrentUser();
  const [token, setToken] = usePersistedState<string | null>('fcm_token', null);
  const [denied, setDenied] = usePersistedState<boolean>('fcm_denied', false);
  const [ready, setReady] = useState<boolean>(false);

  const requestToken = useCallback(
    async ({ quiet }: { quiet?: boolean } = {}) => {
      if (!currentUser) {
        throw new Error('FCM token requested before user was loaded.');
      }

      if (!token) {
        try {
          const _token = await messaging.getToken();

          setToken(_token);
        } catch (err) {
          console.warn(`caught: ${err}`);
          if (!quiet) {
            throw err;
          }
        }
      }
      setReady(true);
    },
    [currentUser, setToken, token],
  );

  const onDenied = useCallback(() => {
    setDenied(true);
  }, [setDenied]);

  // trigger the token request on first load
  useEffect(() => {
    if (currentUser && !token && denied === false) {
      requestToken({ quiet: true });

      // @ts-ignore when the token is refreshed, remove it from the user record.
      return messaging.onTokenRefresh((xyz) => {
        // remove the current token from the list, then refresh
        const fcmTokens = currentUser.fcmTokens.filter((t) => t && t !== token);
        setToken(null);

        currentUser.privateRef.update({ fcmTokens }).then(() => requestToken());
      });
    }
  }, [currentUser, denied, requestToken, setToken, token]);

  // onMessage stub
  useEffect(() => {
    return messaging.onMessage((payload) =>
      console.log('received fcm message: ', payload),
    );
  }, []);

  // update the user record with a new token
  useEffect(() => {
    if (
      token &&
      token !== 'unsupported' &&
      currentUser &&
      !currentUser.fcmTokens.includes(token)
    ) {
      currentUser.privateRef.update({
        fcmTokens: currentUser.fcmTokens.concat([token]),
        fcmAllowedOn: firebase.firestore.Timestamp.now(),
      });
    }
  }, [currentUser, token]);

  return {
    token,
    denied,
    onDenied,
    requestToken,
    ready,
  };
};

export default useCloudMessaging;
