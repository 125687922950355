import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonLabel,
  IonButtons,
  IonButton,
  IonIcon,
  IonChip,
  IonCheckbox,
  IonToggle,
  IonList,
  IonItem,
  IonAlert,
  IonCardContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonTitle,
} from '@ionic/react';
import firebase from '../firebase';
import './GoShopTab.scss';
import Layout from '../layouts/Layout';
import { useCurrentUser } from '../hooks';
import ShoppingListItemsList from '../components/ShoppingListItemsList';
import {
  printOutline,
  closeCircleOutline,
  cartOutline,
  checkmarkDoneCircleOutline,
  mapOutline,
} from 'ionicons/icons';

import { IUser } from '../../types/IUser';
import ActionModal from '../components/ActionModal';
import UserAvatar from '../components/UserAvatar';
import useGroupMembership from '../hooks/useGroupMembership';
import { IGroup } from '../../types/IGroup';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import { IShoppingListItem, IShoppingList } from '../../types/IShoppingList';
import {
  updateShoppingLists,
  toggleShoppingListItemDib,
} from '../helpers/dataMutations';
import { useHistory } from 'react-router';
import CreateShoppingListCTA from '../components/CreateShoppingListCTA';
import ConfirmCompleteShoppingListModal from '../components/ConfirmCompleteShoppingListModal';
import useShoppingLists from '../hooks/useShoppingLists';

interface IProps {
  location: {
    search: string;
  };
}
const GoShopTab: React.FC<IProps> = ({ location: { search: queryString } }) => {
  const currentUser = useRequireCurrentUser();
  const [
    shoppingListItemsRef,
    setShoppingListItemsRef,
  ] = useState<firebase.firestore.Query<
    firebase.firestore.DocumentData
  > | null>();
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>({
    users: {},
    showDibbed: true,
  });
  const [isPrintModeOpen, setIsPrintModeOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IShoppingListItem | null>();

  const { users, groups, loading } = useGroupMembership();
  const { lists: shoppingLists } = useShoppingLists(currentUser, {
    status: 'active',
  });

  const currentUsersLists = useMemo(
    () => shoppingLists.filter((s) => s.userId === currentUser.id),
    [shoppingLists, currentUser],
  );

  const filteredLists = useMemo(
    () =>
      currentUsersLists.filter((list) => list.shoppingStatus === 'shopping'),
    // .filter(
    //   (list) =>
    //     list.userId === currentUser.id ||
    //     filters.users[list.userId] !== false,
    // ),
    // [shoppingLists, currentUser, filters],
    [currentUsersLists],
  );

  useEffect(() => {
    const shoppingListIds = filteredLists.map(
      (shoppingList) => shoppingList.id,
    );

    if (shoppingListIds.length) {
      const ref = firebase
        .firestore()
        .collectionGroup('shoppingListItems')
        .where('shoppingListId', 'in', shoppingListIds);

      setShoppingListItemsRef(ref);
    } else {
      setShoppingListItemsRef(null);
      // TODO - need an empty case here
    }
  }, [filteredLists]);

  const handleTogglePrint = () => {
    setIsPrintModeOpen(!isPrintModeOpen);
  };

  const handleItemDibChange = (item: IShoppingListItem) => {
    toggleShoppingListItemDib(item, currentUser);
  };

  return (
    <Layout
      className="go-shop"
      printMode={isPrintModeOpen}
      pageName={isPrintModeOpen ? 'Go Shopping - Print' : 'Go Shopping'}
    >
      {/* user has no lists to pick */}
      {currentUsersLists.length === 0 && <CreateShoppingListCTA />}

      {/* user hasn't picked a list yet */}
      {currentUsersLists.length > 0 && filteredLists.length === 0 && (
        <StartShopping lists={currentUsersLists} />
      )}

      {/* render the list... it will show a skeleton while loading */}
      {loading ||
        (filteredLists.length > 0 && (
          <>
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonButtons slot="start">
                  {!isPrintModeOpen && (
                    <IonButton onClick={handleTogglePrint} color="secondary">
                      <IonIcon icon={printOutline} />
                      <IonLabel className="ion-hide-sm-down">
                        Print&nbsp;
                      </IonLabel>
                    </IonButton>
                  )}
                  {isPrintModeOpen && (
                    <IonButton onClick={handleTogglePrint} color="danger">
                      <IonIcon icon={closeCircleOutline} />
                      <IonLabel className="ion-hide-sm-down">
                        Exit &nbsp;
                      </IonLabel>
                    </IonButton>
                  )}
                </IonButtons>

                <IonTitle
                  size="small"
                  className="ion-title-stacked ion-title-stacked-top"
                >
                  Shopping
                </IonTitle>
                <IonTitle className="ion-title-stacked">
                  {filteredLists.map((l) => l.name).join(', ')}
                </IonTitle>

                {!isPrintModeOpen && (
                  <IonButtons slot="end">
                    <IonButton onClick={() => setIsFinishModalOpen(true)}>
                      <IonLabel className="ion-hide-sm-down">
                        Finish &nbsp;
                      </IonLabel>
                      <IonIcon icon={checkmarkDoneCircleOutline} />
                    </IonButton>
                  </IonButtons>
                )}
              </IonToolbar>
            </IonHeader>

            {shoppingListItemsRef && (
              <>
                <ShoppingListItemsList
                  shoppingListItemsRef={shoppingListItemsRef}
                  onItemClick={setSelectedItem}
                  isItemClickable={(item) => !!item.notes}
                  onItemToggle={handleItemDibChange}
                  isItemChecked={(item) => !!item.dibbedBy}
                  isItemToggleable={(item) =>
                    !item.deletedOn &&
                    (!item.dibbedBy || item.dibbedBy === currentUser.id)
                  }
                />
                <FinishShoppingModal
                  shoppingLists={filteredLists}
                  shoppingListItemsRef={shoppingListItemsRef}
                  isOpen={isFinishModalOpen}
                  onDismiss={() => setIsFinishModalOpen(false)}
                />
              </>
            )}
          </>
        ))}

      <FiltersModal
        isOpen={isFiltersOpen}
        users={users}
        groups={groups}
        onApply={(partialFilters) => {
          setFilters((f) => ({
            ...f,
            ...partialFilters,
          }));
        }}
        onDismiss={() => setIsFiltersOpen(false)}
      />
      {selectedItem && (
        <IonAlert
          isOpen={!!selectedItem}
          onDidDismiss={() => setSelectedItem(null)}
          header="Notes"
          message={selectedItem.notes || ''}
          buttons={['Ok']}
        />
      )}
    </Layout>
  );
};

interface IStartShoppingProps {
  lists: IShoppingList[];
}

const StartShopping: React.FC<IStartShoppingProps> = ({ lists }) => {
  const [selectedListIds, setSelectedListIds] = useState<string[]>(
    lists.map((l) => l.id),
  );
  const [isConfirmCompleteModalOpen, setIsConfirmCompleteModalOpen] = useState<
    boolean
  >(false);

  const listsBeingDelivered = useMemo(
    () => lists.filter((l) => l.shoppingStatus === 'delivering'),
    [lists],
  );

  const handleListToggle = useCallback(
    (list: IShoppingList, checked: boolean) => {
      if (checked) {
        setSelectedListIds((ids) => Array.from(new Set(ids.concat(list.id))));
      } else {
        setSelectedListIds((ids) => ids.filter((id) => id !== list.id));
      }
    },
    [],
  );

  const handleStartShopping = () => {
    const selectedLists = lists.filter((l) => selectedListIds.includes(l.id));

    updateShoppingLists(selectedLists, { shoppingStatus: 'shopping' });
  };

  if (listsBeingDelivered.length > 0) {
    const handleMarkAsDelivered = async () => {
      setIsConfirmCompleteModalOpen(true);
    };

    const handleContinueShopping = () => {
      const selectedLists = lists.filter((l) => selectedListIds.includes(l.id));
      updateShoppingLists(selectedLists, { shoppingStatus: 'shopping' });
    };

    return (
      <>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Looks like you're still in the process of delivering some items.
            </IonCardTitle>
            <IonCardContent>
              <ul style={{ fontSize: 18 }}>
                {listsBeingDelivered.map((list) => (
                  <li key={list.id}>{list.name}</li>
                ))}
              </ul>
            </IonCardContent>
            <IonButtons style={{ flexWrap: 'wrap', display: 'block' }}>
              <IonButton
                fill="outline"
                onClick={handleMarkAsDelivered}
                color="primary"
                style={{ fontSize: 11, marginTop: 10 }}
                expand="block"
              >
                <IonIcon icon={checkmarkDoneCircleOutline} />
                &nbsp; Mark as delivered
              </IonButton>
              <IonButton
                fill="outline"
                routerLink="/groceries/deliver"
                color="secondary"
                style={{ fontSize: 11, marginTop: 10 }}
                expand="block"
              >
                <IonIcon icon={mapOutline} />
                &nbsp; View delivery details
              </IonButton>
              <IonButton
                fill="outline"
                color="tertiary"
                style={{ fontSize: 11, marginTop: 10 }}
                expand="block"
                onClick={handleContinueShopping}
              >
                <IonIcon icon={cartOutline} />
                &nbsp; Continue Shopping
              </IonButton>
            </IonButtons>
          </IonCardHeader>
        </IonCard>

        <ConfirmCompleteShoppingListModal
          isOpen={isConfirmCompleteModalOpen}
          shoppingLists={listsBeingDelivered}
          onDismiss={() => setIsConfirmCompleteModalOpen(false)}
          additionalData={{ status: 'archived', shoppingStatus: 'delivered' }}
        />
      </>
    );
  }

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Which lists are you shopping today?</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {/* <IonSelect
              placeholder="Choose your list"
              value={activeListIds}
              onIonChange={(e) => setActiveListIds(e.detail.value)}
              multiple
            >
              {currentUsersLists.map((list) => (
                <IonSelectOption value={list.id} key={list.id}>
                  {list.name}
                </IonSelectOption>
              ))}
            </IonSelect> */}

          <IonList>
            {lists.map((list) => (
              <IonItem lines="none" key={list.id}>
                <IonCheckbox
                  slot="start"
                  checked={selectedListIds.includes(list.id)}
                  onIonChange={(e) => handleListToggle(list, e.detail.checked)}
                />
                <IonLabel slot="start">{list.name}</IonLabel>
              </IonItem>
            ))}
          </IonList>

          <p style={{ margin: '20 0', padding: 20 }}>
            Get shopping! When you start shopping we'll notify anyone in your
            group whose items you're going to shop for that you're out looking
            for them.
          </p>

          <IonButton
            expand="block"
            color="primary"
            onClick={handleStartShopping}
          >
            <IonIcon icon={cartOutline} />
            &nbsp; Start shopping
          </IonButton>
        </IonCardContent>
      </IonCard>
    </>
  );
};

interface IFinishShoppingModal {
  shoppingLists: IShoppingList[];
  shoppingListItemsRef: firebase.firestore.Query<
    firebase.firestore.DocumentData
  >;
  isOpen: boolean;
  onDismiss: () => void;
}

const FinishShoppingModal: React.FC<IFinishShoppingModal> = ({
  shoppingLists,
  isOpen,
  onDismiss,
  shoppingListItemsRef,
}) => {
  const history = useHistory();
  const listNames = useMemo(() => shoppingLists.map((l) => l.name).join(', '), [
    shoppingLists,
  ]);
  const currentUser = useCurrentUser();
  const [selectedItems, setSelectedItems] = useState<IShoppingListItem[]>([]);

  const handleItemToggle = (item: IShoppingListItem, value: boolean) => {
    if (value) {
      setSelectedItems((items) => items.concat(item));
    } else {
      setSelectedItems((items) => items.filter((i) => i.id !== item.id));
    }
  };

  const handleDone = async () => {
    if (!currentUser) {
      throw new Error('No user');
    }
    for (const item of selectedItems) {
      await toggleShoppingListItemDib(item, currentUser);
    }
    await updateShoppingLists(shoppingLists, { shoppingStatus: 'delivering' });

    onDismiss();
    history.push('/groceries/deliver');
  };

  return (
    <ActionModal
      title={`Finish ${listNames}`}
      modalName={`Finish ${listNames}`}
      isOpen={isOpen}
      buttons={[
        { label: 'Next', onClick: handleDone },
        { label: 'Cancel', onClick: onDismiss },
      ]}
      onDismiss={onDismiss}
    >
      <>
        <p>
          Before we move on to delivery, were you able to get any of the
          following items?
        </p>
        <ShoppingListItemsList
          shoppingListItemsRef={shoppingListItemsRef}
          groupBy="userId"
          collapsible={false}
          isItemVisible={(item) => !item.dibbedBy && !item.deletedOn}
          checkedItems={selectedItems}
          onItemToggle={handleItemToggle}
          suppressCount
        />
      </>
    </ActionModal>
  );
};

interface IUserFilters {
  [userId: string]: boolean;
}

interface IFilters {
  users: IUserFilters;
  showDibbed: boolean;
}
const FiltersModal = ({
  users,
  onApply,
  isOpen,
  onDismiss,
}: {
  users: IUser[];
  groups: IGroup[];
  onApply: (filters: IFilters) => void;
  isOpen: boolean;
  onDismiss: () => void;
}) => {
  const currentUser = useCurrentUser();
  const [displayedUsers, setDisplayedUsers] = useState<{
    [userId: string]: boolean;
  }>(users.reduce((acc, user) => ({ ...acc, [user.id]: true }), {}));
  const [showDibbed, setShowDibbed] = useState<boolean>(true);

  useEffect(() => {
    const _filteredUsers = users.reduce(
      (acc, user) => ({ ...acc, [user.id]: true }),
      {},
    );

    setDisplayedUsers((us) => ({
      ..._filteredUsers,
      ...us,
    }));
  }, [users]);

  const handleUserClick = (user: IUser, displayed: boolean) => {
    setDisplayedUsers((us) => ({
      ...us,
      [user.id]: displayed,
    }));
  };

  return (
    <ActionModal
      modalName="Go Shop - Filters"
      isOpen={isOpen}
      title="Filter"
      onDismiss={onDismiss}
      buttons={[
        {
          label: 'Apply',
          onClick: () => {
            onApply({ users: displayedUsers, showDibbed });
            onDismiss();
          },
        },
        {
          label: 'Cancel',
        },
      ]}
    >
      <IonList>
        <IonItem lines="full">
          <IonLabel slot="start"> Show Completed</IonLabel>
          <IonToggle
            slot="end"
            checked={showDibbed}
            onIonChange={(e) => setShowDibbed(e.detail.checked)}
          />
        </IonItem>

        {/* <IonItem lines="full">
          <p slot="start">
            <IonLabel position="floating">Groups</IonLabel>
          </p>
          <p slot="end">
            {groups.map((group) => (
              <IonChip key={group.id}>
                <IonLabel>{group.name}</IonLabel>&nbsp;
                <IonCheckbox />
              </IonChip>
            ))}
          </p>
        </IonItem> */}

        <IonItem lines="full">
          <p slot="start">
            <IonLabel position="floating">People</IonLabel>
          </p>
          <p slot="end">
            {users
              .filter((u) => currentUser && u.id !== currentUser.id)
              .map((user) => (
                <IonChip
                  key={user.id}
                  onClick={() =>
                    handleUserClick(user, !displayedUsers[user.id])
                  }
                >
                  <UserAvatar user={user} />
                  <IonLabel>{user.name}</IonLabel>&nbsp;
                  <IonCheckbox checked={displayedUsers[user.id]} />
                </IonChip>
              ))}
          </p>
        </IonItem>
      </IonList>
    </ActionModal>
  );
};

export default GoShopTab;
