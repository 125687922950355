// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from '../firebase';
import Layout from '../layouts/Layout';

import './SignIn.css';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: true,
    },
  ],

  credentialHelper: 'none',
};

const SignIn = () => {
  return (
    <Layout hideHeader pageName="Sign in">
      <div style={{ textAlign: 'center' }}>
        <img
          src="/assets/logo.png"
          alt="Backyard Collective"
          style={{ width: 360 }}
        />
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    </Layout>
  );
};
export default SignIn;
