import {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
  useRef,
} from 'react';
import firebase, { auth, firestore, analytics } from '../firebase';
import { userFromDoc, userPrivateFromDoc } from '../helpers/dataMappers';
import { IUser, IAuthedUser, IUserPrivate } from '../../types/IUser';
import { useHistory } from 'react-router';
import useAnalytics from './useAnalytics';

const CurrentUserContext = createContext<IAuthedUser | null>(null);

/**
 * Return the currently logged in user or null, if not authenticated.
 */
const useCurrentUser = (): IAuthedUser | null => {
  return useContext(CurrentUserContext);
};

/**
 * Binds a listener for auth changes and store the current user when it changes.
 */
const useAuthenticateCurrentUser = () => {
  // const [currentUser, setCurrentUser] = useState<IAuthedUser | null>();
  const [authedUser, setAuthedUser] = useState<firebase.User | null>();
  const [userData, setUserData] = useState<IUser>();
  const [privateData, setPrivateData] = useState<IUserPrivate>();
  const [loading, setLoading] = useState<boolean>(true);
  const currentAuthRef = useRef<firebase.User | null>();
  const { logEvent } = useAnalytics();

  useEffect(() => {
    return auth.onAuthStateChanged((_authedUser) => {
      // store a reference to the currently authed user, whenever it changes (this allows us to detect if the user from unauthed to authed)
      if (currentAuthRef.current === undefined) {
        currentAuthRef.current = _authedUser;
      }

      if (_authedUser) {
        if (_authedUser.uid !== currentAuthRef.current?.uid) {
          currentAuthRef.current = _authedUser;
          logEvent('login', { method: 'email' });
        }

        setAuthedUser(_authedUser);
      } else {
        currentAuthRef.current = null;
        setAuthedUser(null);
        setLoading(false);
      }
    });
  }, [logEvent]);

  useEffect(() => {
    if (authedUser) {
      const ref = firestore.collection('users').doc(authedUser.uid);
      const privateRef = firestore
        .collection('usersPrivate')
        .doc(authedUser.uid);

      analytics.setUserId(authedUser.uid);
      const userUnsub = ref.onSnapshot((snap) => {
        const data = snap.data();
        if (snap.exists || data) {
          // login
          const _userData = userFromDoc(snap);
          setUserData(_userData);
        } else {
          // sign up
          analytics.logEvent('sign_up', { method: 'email' });
          const now = firebase.firestore.Timestamp.now();
          ref.set(
            {
              name: authedUser.displayName,
              photoURL: authedUser.photoURL,
              signedUpAt: now,
              lastLoggedInAt: now,
            },
            { merge: true },
          );
        }
      });

      const privateUnsub = privateRef.onSnapshot((snap) => {
        if (snap.exists) {
          const data = userPrivateFromDoc(snap);

          setPrivateData(data);
        } else {
          privateRef.set(
            {
              fcmTokens: [],
              email: authedUser.email,
            },
            { merge: true },
          );
        }
      });

      return () => {
        userUnsub();
        privateUnsub();
      };
    } else {
      setUserData(undefined);
      setPrivateData(undefined);
    }
  }, [authedUser]);

  const currentUser: IAuthedUser | null = useMemo(() => {
    if (userData && privateData) {
      return {
        ...privateData,
        ...userData,
        privateRef: privateData.ref,
      };
    }

    return null;
  }, [userData, privateData]);

  useEffect(() => {
    if (loading && currentUser) {
      setLoading(false);
    }
  }, [currentUser, loading]);

  const isAuthenticated = useMemo(() => (loading ? undefined : !!currentUser), [
    currentUser,
    loading,
  ]);

  return { currentUser, loading, isAuthenticated };
};

/**
 * Require the user to be logged in.  Throws an error if not logged in.
 */
const useRequireCurrentUser = (): IUser => {
  const user = useCurrentUser();
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      try {
        window.localStorage.setItem(
          'redirectAfterSignIn',
          window.location.toString(),
        );
      } catch {
        console.warn('localStorage not supported');
      }

      history.replace('/sign-in');
    }
  }, [history, user]);

  if (!user) {
    throw new Error('Requires auth');
  }

  return user;
};

export default useCurrentUser;
export {
  CurrentUserContext,
  useAuthenticateCurrentUser,
  useRequireCurrentUser,
};
