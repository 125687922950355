import useTimedLoading from './useTimedLoading';
import { useEffect, useState } from 'react';
import { shoppingListFromDoc } from '../helpers/dataMappers';
import { IShoppingList } from '../../types/IShoppingList';
import { IUser } from '../../types/IUser';

interface IOptions {
  status?: 'active' | 'archived';
}

type ICollectionReference =
  | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
  | firebase.firestore.Query<firebase.firestore.DocumentData>;

const useShoppingLists = (user: IUser, options: IOptions = {}) => {
  const [lists, setLists] = useState<IShoppingList[]>([]);

  const { loading, start: startLoading, clear: clearLoading } = useTimedLoading(
    1500,
  );

  useEffect(() => {
    startLoading();
    let collectionRef: ICollectionReference = user.ref.collection(
      'shoppingLists',
    );
    if (options.status) {
      collectionRef = collectionRef.where('status', '==', options.status);
    }
    return collectionRef.onSnapshot((snap) => {
      clearLoading();
      const _lists = snap.docs.map(shoppingListFromDoc);
      setLists(
        _lists.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()),
      );
    });
  }, [user, startLoading, clearLoading, options.status]);

  return {
    lists,
    loading,
  };
};

export default useShoppingLists;
