import useCurrentUser from './useCurrentUser';
import firebase from '../firebase';
import { useCallback } from 'react';

const useSaveAvatar = () => {
  const currentUser = useCurrentUser();

  const saveAvatar = useCallback(
    async (data: Blob) => {
      if (currentUser) {
        const { type } = data;
        const [dataType, ext] = type.split('/');
        if (dataType !== 'image' || !['png', 'jpg'].includes(ext)) {
          throw new Error('Invalid file type');
        }

        const ref = firebase
          .storage()
          .ref()
          .child('profilePics')
          .child(currentUser.id)
          .child(`${currentUser.id}.${ext}`);

        await ref.put(data);
        const photoURL = await ref.getDownloadURL();

        await currentUser.ref.update({ photoURL });
      }
    },
    [currentUser],
  );

  return {
    saveAvatar,
  };
};

export default useSaveAvatar;
