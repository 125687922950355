import uniqBy from 'lodash/uniqBy';
import partition from 'lodash/partition';

const bestMatchSort = <T extends { [key: string]: any }>(
  list: T[],
  query: string,
  comparisonField: string,
): T[] => {
  const [bestResults, otherResults] = partition(
    uniqBy(list, comparisonField),
    (r) => r[comparisonField].toLowerCase().startsWith(query.toLowerCase()),
  ).map((arr) =>
    arr.sort((a, b) => a[comparisonField].localeCompare(b[comparisonField])),
  );

  return bestResults.concat(otherResults);
};

export default bestMatchSort;
