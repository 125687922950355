const spoonacularImageSrc = (
  path: string | null,
  size: '100' | '250' | '500' = '100',
) => {
  if (!path) {
    return 'https://p.kindpng.com/picc/s/79-798754_hoteles-y-centros-vacacionales-dish-placeholder-hd-png.png';
  } else if (path.startsWith('http')) {
    return path;
  } else {
    return `https://spoonacular.com/cdn/ingredients_${size}x${size}/${path}`;
  }
};

export default spoonacularImageSrc;
