import React from 'react';
import { IonLabel } from '@ionic/react';

const FabLabel: React.FC<{ children: React.ReactChild; color?: string }> = ({
  color,
  children,
}) => (
  <IonLabel
    color={color}
    style={{
      position: 'relative',
      right: 120,
      top: 34,
      textAlign: 'left',
      width: 150,
      fontSize: 14,
      display: 'inline-block',
      float: 'right',
      backgroundColor: `var(--ion-color-${color})`,
      color: `var(--ion-color-${color}-contrast)`,
      padding: 6,
      paddingLeft: 12,
      borderRadius: 20,
    }}
  >
    {children}
  </IonLabel>
);
export default FabLabel;
