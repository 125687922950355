import uniq from 'lodash/uniq';
import chunk from 'lodash/chunk';
import { ISnapshot } from '../../types/Firebase';

/**
 * Firestore has an annoying limit of no more than 10 values in an "in" query.
 * @param {*} collectionRef
 * @param {*} field
 * @param {*} values
 * @param {*} onSnapshot
 * @param {*} onError
 */
const safeFirestoreIn = (
  collectionRef:
    | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    | firebase.firestore.Query<firebase.firestore.DocumentData>,
  field: string | firebase.firestore.FieldPath,
  values: any[],
): ISnapshot => {
  const queries = chunk(uniq(values), 10).map((chunkedValues) =>
    collectionRef.where(field, 'in', chunkedValues),
  );

  return {
    onSnapshot: (onNext, onError): (() => void) => {
      const unsubs = queries.map((query) => query.onSnapshot(onNext, onError));

      return () => unsubs.forEach((unsub) => unsub());
    },
  };
};

export { safeFirestoreIn };
