export const AISLES = [
  'Alcoholic Beverages',
  'Bakery/Bread',
  'Baking',
  'Beverages',
  'Bread',
  'Canned and Jarred',
  'Cereal',
  'Cheese',
  'Cleaning',
  'Condiments',
  'Dried Fruits',
  'Ethnic Foods',
  'Frozen',
  'Gluten Free',
  'Gourmet',
  'Grilling Supplies',
  'Health Foods',
  'Meat',
  'Milk, Eggs, Other Dairy',
  'Not in Grocery Store/Homemade',
  'Nut butters, Jams, and Honey',
  'Nuts',
  'Oil, Vinegar, Salad Dressing',
  'Online',
  'Other',
  'Pasta and Rice',
  'Produce',
  'Refrigerated',
  'Savory Snacks',
  'Seafood',
  'Spices and Seasonings',
  'Sweet Snacks',
  'Tea and Coffee',
];

export const UNITS = [
  'NLEA serving',
  'cup',
  'cup mashed',
  'cup slice',
  'dash',
  'each',
  'extra large',
  'extra small',
  'g',
  'handful',
  'large',
  'medium',
  'oz',
  'piece',
  'pinch',
  'serving',
  'slice',
  'small',
  'tablespoon',
  'teaspoon',
];
