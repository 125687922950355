import React, { useEffect, useState, useMemo } from 'react';
import useAnalytics from '../hooks/useAnalytics';
import Layout from '../layouts/Layout';

interface IState {
  hasError: boolean;
  error: Error | null;
}

const AppErrorBoundaryFC: React.FC<IState> = ({ error: _error, children }) => {
  const { logEvent } = useAnalytics();
  const [windowError, setWindowError] = useState<Error>();

  const error: Error | undefined = useMemo(() => _error || windowError, [
    _error,
    windowError,
  ]);

  useEffect(() => {
    if (error) {
      logEvent('exception', {
        fatal: true,
        description: error.message,
      });
    }
  }, [error, logEvent]);

  useEffect(() => {
    const cb = (e: ErrorEvent | PromiseRejectionEvent) => {
      const _error = 'error' in e ? e.error : new Error(e.reason);

      console.error('caught: ', _error);
      setWindowError(_error);
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      return true;
    };

    window.addEventListener('error', cb);
    window.addEventListener('unhandledrejection', cb);

    return () => {
      window.removeEventListener('error', cb);
      window.removeEventListener('unhandledrejection', cb);
    };
  }, []);

  if (error) {
    if (error.message === 'Requires auth') {
      return null;
    }
    return (
      <Layout pageName="error">
        <h1>{error.message}</h1>
        <code>{error.stack}</code>
      </Layout>
    );
  }

  return <>{children}</>;
};
export default class AppErrorBoundary extends React.Component<
  { children: React.ReactNode },
  IState
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: !!error, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {}

  render() {
    if (!this.props.children) {
      throw new Error('AppErrorBoundary requires children');
    }
    return (
      <AppErrorBoundaryFC
        hasError={this.state.hasError}
        error={this.state.error}
      >
        {this.props.children}
      </AppErrorBoundaryFC>
    );
  }
}
