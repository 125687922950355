import React, { useMemo } from 'react';
import { IUser } from '../../types/IUser';
import { IonAvatar } from '@ionic/react';

interface IProps {
  user: IUser;
  size?: number;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  slot?: 'start' | 'end';
  onClick?: (event: React.MouseEvent<HTMLIonAvatarElement, MouseEvent>) => void;
}

const UserAvatar: React.FC<IProps> = ({
  user,
  size,
  style = {},
  imgStyle = {},
  slot,
  onClick,
}) => {
  const { photoURL, name } = user;

  const src = useMemo(() => {
    if (photoURL) {
      return photoURL;
    } else {
      return `https://api.adorable.io/avatars/${size}/${name}.png`;
    }
  }, [size, photoURL, name]);

  const _style = {
    width: size,
    height: size,
    ...style,
  };
  const _imgStyle = {
    width: size,
    height: size,
    ...imgStyle,
  };

  return (
    <IonAvatar
      style={{ ..._style }}
      slot={slot}
      onClick={onClick}
      className="user-avatar"
    >
      <img src={src} alt={name} style={{ ..._imgStyle }} />
    </IonAvatar>
  );
};

export default UserAvatar;
