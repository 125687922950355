import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import Layout from '../layouts/Layout';
import {
  IonToolbar,
  IonTitle,
  IonIcon,
  IonAlert,
  IonFab,
  IonFabButton,
  IonFabList,
  IonLabel,
  IonHeader,
  IonMenu,
  IonContent,
  IonButtons,
  IonMenuToggle,
  IonSplitPane,
  IonButton,
  IonCardContent,
  IonCard,
} from '@ionic/react';
import {
  trashOutline,
  add,
  ellipsisVerticalOutline,
  checkmarkDoneOutline,
  arrowUndoOutline,
  cartOutline,
  closeOutline,
  chevronBackOutline,
  addCircleOutline,
  pencilOutline,
  peopleCircleOutline,
} from 'ionicons/icons';
import ProductSearch from '../components/ProductSearch';
import {
  fetchIngredient,
  fetchProduct,
  IUnifiedAutocompleteItem,
  IUnifiedFoodItem,
} from '../hooks/useSpoonacular';

import './ViewShoppingList.scss';

import {
  IShoppingList,
  IShoppingListItem,
  QuantityType,
} from '../../types/IShoppingList';
import ShoppingListItemsList from '../components/ShoppingListItemsList';
import ActionModal from '../components/ActionModal';
import EditShoppingListItemModal from '../components/EditShoppingListItemModal';
import {
  shoppingListFromDoc,
  foodItemFromDoc,
  shoppingListItemFromDoc,
} from '../helpers/dataMappers';
import firebase from '../firebase';
import useGroupMembership from '../hooks/useGroupMembership';
import { safeFirestoreIn } from '../helpers/firebase';
import {
  addShoppingListItem,
  removeShoppingListItem,
  updateShoppingLists,
} from '../helpers/dataMutations';
import FabButton from '../components/FabButton';
import AddEditShoppingListModal from '../components/AddEditShoppingListModal';
import { useHistory } from 'react-router';
import ConfirmCompleteShoppingListModal from '../components/ConfirmCompleteShoppingListModal';
import useGlobalToast from '../hooks/useGlobalToast';
interface IProps {
  match: {
    params: {
      id: string;
    };
  };

  history: {
    push: (uri: string) => void;
  };
}

interface IAddItemModalProps {
  isOpen: boolean;
  shoppingList: IShoppingList;
  onDismiss: () => void;
}

interface IShoppingListItemData {
  id: string;
  quantity: number;
  type: 'product' | 'ingredient';
  name: string;
  quantityType: QuantityType;
  notes?: string;
  source: 'spoonacular' | 'custom';
}

const AddItemModal: React.FC<IAddItemModalProps> = ({
  isOpen,
  shoppingList,
  onDismiss,
}) => {
  const [selected, setSelected] = useState<{
    [id: string]: IShoppingListItemData | null;
  }>({});
  const [selectedProductSearchItem, setSelectedProductSearchItem] = useState<
    IUnifiedAutocompleteItem
  >();

  const currentUser = useRequireCurrentUser();

  const clearModal = () => {
    onDismiss();
  };
  const handleAddItems = () => {
    const promises = Object.keys(selected)
      .map(async (id) => {
        const selectedItem = selected[id];
        if (!selectedItem) {
          throw new Error('Tried to add item not in selected map');
        }

        const {
          type: selectedType,
          quantity = 1,
          quantityType,
          notes = null,
          source,
          name,
        } = selectedItem || {};

        if (selectedType) {
          let fetchItem;

          if (source === 'custom') {
            fetchItem = (_id: string): Promise<IUnifiedFoodItem> =>
              currentUser.ref
                .collection('customItems')
                .doc(_id)
                .get()
                .then(foodItemFromDoc)
                .then((data) => ({
                  ...data,
                  source: 'custom',
                }));
          } else if (selectedType === 'ingredient') {
            fetchItem = fetchIngredient;
          } else if (selectedType === 'product') {
            fetchItem = fetchProduct;
          } else {
            throw new Error('Cannot fetch item');
          }

          const item = await fetchItem(id);

          return addShoppingListItem(shoppingList, {
            name, // note, there is a bug in spoonacular such that the name in the ingredient detail result does not match the search result.  Lame.
            aisle: item.aisle,
            type: item.type,
            refId: item.id,
            refSource: item.source,
            image: item.image || '',
            userId: currentUser.id,
            shoppingListId: shoppingList.id,
            quantity,
            quantityType,
            notes,
          });
        }
      })
      .filter((p) => p);

    Promise.all(promises)
      .then(() => setSelected({}))
      .then(clearModal);
  };

  const handleDeleteCustomItem = () => {
    if (selectedProductSearchItem) {
      currentUser.ref
        .collection('customItems')
        .doc(selectedProductSearchItem.id)
        .delete()
        .then(() => onDismiss());
    }
  };

  const selectedItemCount = useMemo(
    () => Object.values(selected).filter(Boolean).length,
    [selected],
  );

  return (
    <ActionModal
      title="Add Items"
      modalName="Add Items"
      isOpen={isOpen}
      valid={selectedItemCount > 0}
      buttons={[
        {
          label: `Add (${selectedItemCount})`,
          onClick: handleAddItems,
        },
        {
          label: 'Cancel',
        },
      ]}
      onDismiss={clearModal}
    >
      <>
        <ProductSearch
          onSelectItem={(result) => {
            if (!selected[result.id]) {
              setSelected({
                ...selected,
                [result.id]: { quantity: 1, quantityType: 'each', ...result },
              });
            }
          }}
          onDeselectItem={(result) => {
            setSelected({ ...selected, [result.id]: null });
          }}
          onClickItem={(result) => setSelectedProductSearchItem(result)}
          selectedItemIds={Object.keys(selected).filter((id) => !!selected[id])}
        />
        {selectedProductSearchItem && (
          <EditShoppingListItemModal
            isOpen={!!selectedProductSearchItem}
            item={
              selected[selectedProductSearchItem.id] ||
              selectedProductSearchItem
            }
            onDismiss={() => setSelectedProductSearchItem(undefined)}
            onUpdate={(result) => {
              if (result?.quantity) {
                setSelected({
                  ...selected,
                  [selectedProductSearchItem.id]: {
                    ...selectedProductSearchItem,
                    ...result,
                  },
                });
              } else {
                setSelected({
                  ...selected,
                  [selectedProductSearchItem.id]: null,
                });
              }
            }}
            onDeleteClick={
              selectedProductSearchItem.source === 'custom'
                ? handleDeleteCustomItem
                : undefined
            }
          />
        )}
      </>
    </ActionModal>
  );
};

const ShoppingList = (props: IProps) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  if (!id) {
    throw new Error('Not found');
  }

  const currentUser = useRequireCurrentUser();
  const { setGlobalToast } = useGlobalToast();
  const [shoppingList, setShoppingList] = useState<IShoppingList>();
  const shoppingListsForCompleteModal = useMemo(
    () => (shoppingList ? [shoppingList] : []),
    [shoppingList],
  );
  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
  const [
    isEditShoppingListModalOpen,
    setIsEditShoppingListModalOpen,
  ] = useState<boolean>(false);
  const [isConfirmCompleteModalOpen, setIsConfirmCompleteModalOpen] = useState<
    boolean
  >(false);
  const [
    selectedShoppingListItem,
    setSelectedShoppingListItem,
  ] = useState<IShoppingListItem | null>();
  const [
    showDetailsShoppingListItem,
    setShowDetailsShoppingListItem,
  ] = useState<IShoppingListItem | null>();
  const [itemsClonedFromGroup, setItemsClonedFromGroup] = useState<{
    [newId: string]: IShoppingListItem;
  }>({});
  const { shoppingLists } = useGroupMembership();
  const history = useHistory();

  // set the currently active shopping list
  useEffect(() => {
    if (id && currentUser) {
      return currentUser.ref
        .collection('shoppingLists')
        .doc(id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setShoppingList(shoppingListFromDoc(doc));
          } else {
            history.replace('/groceries');
          }
        });
    }
  }, [id, currentUser, history]);

  const handleToggleArchive = useCallback(() => {
    if (shoppingList) {
      if (shoppingList.status === 'active') {
        setIsConfirmCompleteModalOpen(true);
        return;
      }
      const { status } = shoppingList;
      const newStatus = status === 'archived' ? 'active' : 'archived';
      shoppingList.ref
        .update({
          status: newStatus,
        })
        .then(() => {
          if (newStatus === 'active') {
            setGlobalToast(
              'List restored.  Others in your groups will see your items.',
            );
          }
        });
    }
  }, [setGlobalToast, shoppingList]);

  // a reference to the shoppingListItems for the current list
  const shoppingListItemsRef = useMemo(
    () => shoppingList && shoppingList.ref.collection('shoppingListItems'),
    [shoppingList],
  );

  // a reference to all of the possible shopping list items of the group, excluding the list currently being viewed
  const groupShoppingItemsRef = useMemo(() => {
    if (currentUser) {
      return safeFirestoreIn(
        firebase.firestore().collectionGroup('shoppingListItems'),
        'shoppingListId',
        shoppingLists
          .filter((s) => s.userId !== currentUser.id)
          .map((s) => s.id),
      );
    }
  }, [shoppingLists, currentUser]);

  // compute the items that have been added to this list from another user's list
  useEffect(() => {
    return shoppingListItemsRef?.onSnapshot((snap) => {
      const _itemsClonedFromGroup = snap.docs
        .map(shoppingListItemFromDoc)
        .filter((i) => Boolean(i.clonedFromItemId))
        .reduce(
          (acc, i) => ({
            ...acc,
            [i.clonedFromItemId || '']: i,
          }),
          {},
        );

      setItemsClonedFromGroup(_itemsClonedFromGroup);
    });
  }, [shoppingListItemsRef]);

  const checkedGroupItems = useMemo(
    () =>
      Object.values(itemsClonedFromGroup).map((i) => ({
        ...i,
        id: i.clonedFromItemId || '',
      })),
    [itemsClonedFromGroup],
  );

  const viewedListRef = useMemo(() => {
    if (currentUser?.id && shoppingListItemsRef) {
      return shoppingListItemsRef.where('userId', '==', currentUser.id);
    }
  }, [currentUser, shoppingListItemsRef]);

  if (!shoppingList) {
    return null;
  }

  const handleDelete = () => {
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirmed = () => {
    shoppingList.ref.delete().then(() => history.push('/'));
  };

  const handleItemDelete = () => {
    if (selectedShoppingListItem) {
      selectedShoppingListItem.ref.delete();
    }
  };

  const handleGroupItemToggle = (item: IShoppingListItem, val: boolean) => {
    if (val) {
      addShoppingListItem(shoppingList, {
        ...item,
        clonedFromItemId: item.originalItemId,
        clonedFromItemPath: item.originalItemPath,
      });
    } else {
      const localItem = itemsClonedFromGroup[item.originalItemId];
      if (localItem) {
        removeShoppingListItem(shoppingList, localItem);
      }
    }
  };

  const handleStartShopping = async () => {
    await updateShoppingLists([shoppingList], { shoppingStatus: 'shopping' });
    history.push('/groceries/shop');
  };

  return (
    <IonSplitPane contentId="viewList">
      <IonMenu
        side="end"
        menuId="groupItemsMenu"
        contentId="viewList"
        className="ion-menu-large"
      >
        <IonHeader>
          <IonToolbar color="secondary">
            <IonButtons slot="end">
              <IonMenuToggle menu="groupItemsMenu">
                <IonButton>
                  <IonIcon icon={closeOutline} slot="icon-only" />
                </IonButton>
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Items for my groups</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <p className="ion-padding">
            Any items you check off below will be added to your list when you go
            shopping if they're still needed.
          </p>
          {groupShoppingItemsRef && (
            <ShoppingListItemsList
              shoppingListItemsRef={groupShoppingItemsRef}
              onItemToggle={handleGroupItemToggle}
              isItemToggleable={(item) => !item.dibbedBy}
              isItemVisible={(item) =>
                item.userId !== currentUser.id && !item.dibbedBy
              }
              onItemClick={(item) => setShowDetailsShoppingListItem(item)}
              isItemClickable={(item) => !!item.notes}
              groupBy="userId"
              subgroupBy="shoppingListId"
              checkedItems={checkedGroupItems}
              suppressCount
            />
          )}
        </IonContent>
      </IonMenu>
      <Layout
        canGoBack="/groceries/shopping-lists"
        id="viewList"
        pageName="View Shopping List"
      >
        <IonToolbar>
          <IonTitle
            size="small"
            className="ion-title-stacked ion-title-stacked-top"
          >
            {shoppingList.status === 'archived' ? 'Archived' : 'Planning'}
          </IonTitle>
          <IonTitle className="ion-title-stacked">{shoppingList.name}</IonTitle>
          <IonButtons slot="end">
            <IonMenuToggle menu="groupItemsMenu">
              <IonButton color="secondary">
                <IonIcon icon={chevronBackOutline} />
                &nbsp;
                <IonIcon
                  icon={peopleCircleOutline}
                  className="ion-hide-md-up"
                />
                <IonLabel className="ion-hide-md-down">
                  Items for my groups
                </IonLabel>
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>

        {/* <IonToolbar>
          <IonButtons slot="end">
            <IonMenuToggle menu="groupItemsMenu">
              <IonButton fill="outline" color="secondary">
                <IonIcon icon={chevronBackOutline} />
                &nbsp;
                <IonLabel>Items for my groups</IonLabel>
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar> */}

        {viewedListRef && (
          <ShoppingListItemsList
            shoppingListItemsRef={viewedListRef}
            onItemClick={(item) => setSelectedShoppingListItem(item)}
            isItemClickable={() => shoppingList.status === 'active'}
            suppressCount
            fallback={
              <IonCard>
                <IonCardContent>
                  <p>
                    This is where you'll build your shopping list. Items that
                    you add to your list will be visible for friends in your
                    group to buy for you.
                  </p>
                </IonCardContent>
                <IonCardContent>
                  <IonButtons
                    style={{
                      display: 'block',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <IonButton
                      fill="outline"
                      color="primary"
                      onClick={() => setShowAddItemModal(true)}
                      expand="block"
                    >
                      <IonIcon icon={addCircleOutline} />
                      Add some items
                    </IonButton>
                  </IonButtons>
                </IonCardContent>
              </IonCard>
            }
          />
        )}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={ellipsisVerticalOutline} />
          </IonFabButton>
          <IonFabList side="top" className="fab-list-with-labels">
            {shoppingList.status === 'active' && (
              <FabButton
                color="secondary"
                onClick={() => setShowAddItemModal(true)}
                size="small"
                label="Add items"
              >
                <IonIcon icon={add} />
              </FabButton>
            )}
            <FabButton
              label="Delete"
              color="danger"
              onClick={handleDelete}
              size="small"
            >
              <IonIcon icon={trashOutline} />
            </FabButton>

            <FabButton
              color="dark"
              onClick={handleToggleArchive}
              size="small"
              label={
                shoppingList.status === 'active' ? 'Mark completed' : 'Restore'
              }
            >
              {shoppingList.status === 'active' && (
                <IonIcon icon={checkmarkDoneOutline} />
              )}
              {shoppingList.status === 'archived' && (
                <IonIcon icon={arrowUndoOutline} />
              )}
            </FabButton>

            {shoppingList.status === 'active' && (
              <FabButton
                color="tertiary"
                size="small"
                label="Edit"
                onClick={() => setIsEditShoppingListModalOpen(true)}
              >
                <IonIcon icon={pencilOutline} />
              </FabButton>
            )}

            {shoppingList.status === 'active' && (
              <FabButton
                label="Start shopping"
                color="success"
                routerLink={`/groceries/shop?activeListId=${shoppingList.id}`}
                size="small"
                onClick={handleStartShopping}
              >
                <IonIcon icon={cartOutline} />
              </FabButton>
            )}
          </IonFabList>
        </IonFab>

        <AddItemModal
          isOpen={showAddItemModal}
          shoppingList={shoppingList}
          onDismiss={() => setShowAddItemModal(false)}
        />

        <AddEditShoppingListModal
          isOpen={isEditShoppingListModalOpen}
          onDismiss={() => setIsEditShoppingListModalOpen(false)}
          shoppingList={shoppingList}
        />

        {isConfirmCompleteModalOpen && (
          <ConfirmCompleteShoppingListModal
            isOpen={isConfirmCompleteModalOpen}
            shoppingLists={shoppingListsForCompleteModal}
            onDismiss={(result) => setIsConfirmCompleteModalOpen(false)}
          />
        )}

        {selectedShoppingListItem && (
          <EditShoppingListItemModal
            isOpen={!!selectedShoppingListItem}
            item={selectedShoppingListItem}
            onDismiss={() => setSelectedShoppingListItem(undefined)}
            onDeleteClick={handleItemDelete}
            onUpdate={({ quantity, quantityType, notes }) => {
              selectedShoppingListItem.ref.update({
                quantity,
                quantityType,
                notes,
              });
            }}
          />
        )}
        <IonAlert
          isOpen={isDeleteAlertOpen}
          onDidDismiss={() => setIsDeleteAlertOpen(false)}
          header="Delete?"
          message="Are you sure you want to delete this list?"
          buttons={[
            { text: 'Cancel', role: 'cancel', cssClass: 'secondary' },
            {
              text: 'Delete',
              role: 'destructive',
              cssClass: 'danger',
              handler: handleDeleteConfirmed,
            },
          ]}
        />

        {showDetailsShoppingListItem && (
          <IonAlert
            isOpen={!!showDetailsShoppingListItem}
            onDidDismiss={() => setShowDetailsShoppingListItem(null)}
            header="Notes"
            message={showDetailsShoppingListItem.notes || ''}
            buttons={['Ok']}
          />
        )}
      </Layout>
    </IonSplitPane>
  );
};

export default ShoppingList;
