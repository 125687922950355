import * as React from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import { documentTextOutline } from 'ionicons/icons';

const CreateShoppingListCTA = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Create your first list to get started</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <p style={{ marginBottom: 30 }}>
          Before you can start shopping, you need to add a new list and add some
          items to it.
        </p>
        <IonButtons style={{ width: 'auto', display: 'block' }}>
          <IonButton
            expand="block"
            routerLink="/groceries/shopping-lists"
            color="primary"
            fill="outline"
          >
            <IonIcon icon={documentTextOutline} />
            &nbsp;
            <IonLabel>Take me there</IonLabel>
          </IonButton>
        </IonButtons>
      </IonCardContent>
    </IonCard>
  );
};

export default CreateShoppingListCTA;
