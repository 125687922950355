import React, { useCallback, useState } from 'react';
import useCloudMessaging from '../hooks/useCloudMessaging';
import { IonAlert, IonToast } from '@ionic/react';

const CloudMessagingRequest = () => {
  const { token, requestToken, ready, denied, onDenied } = useCloudMessaging();
  const [toastMessage, setToastMessage] = useState<string>();

  const handleEnrollmentClick = useCallback(async () => {
    try {
      await requestToken();
      setToastMessage("Great!  We'll notify you when important stuff happens!");
    } catch {
      onDenied();
      setToastMessage("No problem, we won't bug you!");
    }
  }, [onDenied, requestToken]);

  const handleCancelClick = () => {
    onDenied();
    setToastMessage("No problem, we won't bug you!");
  };

  return (
    <>
      <IonAlert
        isOpen={!token && !denied && ready}
        header="Allow notifications"
        message="We'll update you when your group has picked up items for you and other helpful notifications."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: handleCancelClick,
          },
          { text: 'Ok', handler: handleEnrollmentClick },
        ]}
      />
      <IonToast
        isOpen={!!toastMessage}
        message={toastMessage}
        onDidDismiss={() => setToastMessage(undefined)}
        duration={3000}
      />
    </>
  );
};

export default CloudMessagingRequest;
