import { useState, useEffect } from 'react';
import { IShoppingListItem } from '../../types/IShoppingList';
import { shoppingListItemFromDoc } from '../helpers/dataMappers';
import firebase from '../firebase';
import { ISnapshot } from '../../types/Firebase';
import useTimedLoading from './useTimedLoading';

type FirestoreCollection = firebase.firestore.CollectionReference<
  firebase.firestore.DocumentData
>;

interface IOptions {
  dibs?: boolean;
}

const useShoppingListItems = (shoppingListItemsRef: ISnapshot | undefined) => {
  const [items, setItems] = useState<IShoppingListItem[]>();
  const {
    loading = true,
    start: startLoading,
    clear: clearLoading,
  } = useTimedLoading(1500);

  /**
   * Set the shopping list items
   */
  useEffect(() => {
    if (shoppingListItemsRef && startLoading && clearLoading) {
      startLoading();
      return shoppingListItemsRef.onSnapshot((snap) => {
        const _items = snap.docs.map((doc) => shoppingListItemFromDoc(doc));
        clearLoading();
        setItems(_items);
      });
    }
  }, [clearLoading, shoppingListItemsRef, startLoading]);

  return {
    items,
    loading,
  };
};

export default useShoppingListItems;
