import * as firebase from 'firebase/app';
import { FCM } from '@ionic-native/fcm';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/functions';
import config from './firebase.config.json';
import { Capacitor } from '@capacitor/core';

firebase.initializeApp(config);
const firestore = firebase.firestore();
const analytics = firebase.analytics();
const auth = firebase.auth();

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_FIREBASE_EMULATION === 'true'
) {
  firebase.functions().useFunctionsEmulator('http://localhost:5000');
  firestore.settings({
    host: 'localhost:8080',
    ssl: false,
  });
}

interface IFirebaseMessaging {
  getToken: () => Promise<string>;
  onTokenRefresh: (cb: () => void) => firebase.Unsubscribe;
  onMessage: (cb: (payload: object) => void) => firebase.Unsubscribe;
}

let _messagingClient: IFirebaseMessaging;

const getMessagingClient = async () => {
  if (!_messagingClient) {
    try {
      // first try webpush messaging, this will throw if in an unsupported browser.
      const fbMessaging = firebase.messaging();
      fbMessaging.usePublicVapidKey(
        // @ts-ignore
        config.messagingPublicVapidKey,
      );

      _messagingClient = {
        getToken: fbMessaging.getToken.bind(fbMessaging),
        onTokenRefresh: (cb) => fbMessaging.onTokenRefresh(cb),
        onMessage: (cb) => fbMessaging.onMessage(cb),
      };
    } catch (err) {
      console.warn(err);
      try {
        // then try native.  FCM.hasPermission will throw if not in native.
        await FCM.hasPermission();
        const platform = Capacitor.getPlatform();
        if (platform === 'ios') {
          throw new Error('Not yet supported on ios');
        }

        _messagingClient = {
          getToken: FCM.getToken.bind(FCM),
          onTokenRefresh: (cb: () => void) => {
            const sub = FCM.onTokenRefresh().subscribe(cb);

            return () => sub.unsubscribe();
          },
          onMessage: (cb) => {
            const sub = FCM.onNotification().subscribe(cb);

            return () => sub.unsubscribe();
          },
        };
      } catch (err) {
        // finally, stub the client if we don't support web OR native
        console.warn(err);
        _messagingClient = {
          getToken: async () => 'unsupported',
          onTokenRefresh: () => () => {},
          onMessage: () => () => {},
        };
      }
    }
  }

  return _messagingClient;
};
const messaging: IFirebaseMessaging = {
  getToken: async () => {
    const client = await getMessagingClient();
    return client.getToken();
  },
  onTokenRefresh: (cb) => {
    const promise = getMessagingClient().then((client) => {
      return client.onTokenRefresh(cb);
    });

    return () => promise.then((unsub) => unsub());
  },
  onMessage: (cb) => {
    const promise = getMessagingClient().then((client) => {
      return client.onMessage(cb);
    });

    return () => promise.then((unsub) => unsub());
  },
};

export default firebase;

export { firestore, config, messaging, analytics, auth };
