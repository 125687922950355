import { useState, useCallback, useEffect } from 'react';

const usePersistedState = <S>(
  storageKey: string,
  initialState: S | (() => S),
): [S, (val: S) => void] => {
  const [_val, _setVal] = useState(_getItem(storageKey) || initialState);

  const setVal = useCallback(
    (value: S) => {
      if (window?.localStorage) {
        window.localStorage.setItem(storageKey, JSON.stringify(value));
      }

      _setVal(value);
    },
    [storageKey],
  );

  useEffect(() => {
    if (window?.localStorage) {
      const value = _getItem(storageKey);
      if (value) {
        _setVal(value);
      } else if (initialState) {
        const initialValue =
          initialState instanceof Function ? initialState() : initialState;
        window.localStorage.setItem(storageKey, JSON.stringify(initialValue));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey]);

  return [_val, setVal];
};

const _getItem = (storageKey: string) => {
  const storedValue = window.localStorage.getItem(storageKey);
  if (storedValue) {
    return JSON.parse(storedValue);
  }
};

export default usePersistedState;
