import { useContext, createContext, useCallback } from 'react';

const GlobalToastContext = createContext<{
  toast: IToast | null;
  setToast: (toast: IToast | null) => void;
}>({
  toast: null,
  setToast: () => {},
});

export interface IToast {
  message: string;
  duration?: number;
}
const useGlobalToast = () => {
  const { setToast } = useContext(GlobalToastContext) || {};

  const setGlobalToast = useCallback(
    (_toast: string | IToast) => {
      let toast;
      if (typeof _toast === 'string') {
        toast = {
          message: _toast,
          duration: 3000,
        };
      } else {
        toast = _toast;
      }

      setToast(toast);
    },
    [setToast],
  );

  return {
    setGlobalToast,
  };
};

export default useGlobalToast;
export { GlobalToastContext };
