import React, { useMemo, useState, useEffect } from 'react';
import Layout from '../layouts/Layout';
import {
  IonToolbar,
  IonTitle,
  IonHeader,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonCard,
  IonCardContent,
  IonList,
  IonSkeletonText,
  IonAvatar,
  IonIcon,
  IonButtons,
  IonButton,
} from '@ionic/react';
import firebase from '../firebase';

import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import partition from 'lodash/partition';

import useGroupMembership from '../hooks/useGroupMembership';
import useCurrentUser, { useRequireCurrentUser } from '../hooks/useCurrentUser';
import useShoppingListItems from '../hooks/useShoppingListItems';

import UserAvatar from '../components/UserAvatar';
import { IUser } from '../../types/IUser';
import { IShoppingListItem, IShoppingList } from '../../types/IShoppingList';
import {
  closeCircleOutline,
  checkmarkCircleOutline,
  checkmarkDoneCircleOutline,
} from 'ionicons/icons';
import CreateShoppingListCTA from '../components/CreateShoppingListCTA';
import ConfirmCompleteShoppingListModal from '../components/ConfirmCompleteShoppingListModal';
import { shoppingListFromDoc } from '../helpers/dataMappers';

const DeliverGroceriesTab = () => {
  const currentUser = useRequireCurrentUser();
  const [listsBeingDelivered, setListsBeingDelivered] = useState<
    IShoppingList[]
  >([]);

  const { users } = useGroupMembership();
  const [isConfirmCompleteOpen, setIsConfirmCompleteOpen] = useState<boolean>(
    false,
  );

  const usersById: { [userId: string]: IUser } = useMemo(
    () => keyBy(users, 'id'),
    [users],
  );

  useEffect(() => {
    return currentUser.ref
      .collection('shoppingLists')
      .where('status', '==', 'active')
      .where('shoppingStatus', '==', 'delivering')
      .onSnapshot((snap) => {
        const lists = snap.docs.map(shoppingListFromDoc);
        setListsBeingDelivered(lists);
      });
  }, [currentUser]);

  const shoppingListItemsRef = useMemo(() => {
    if (listsBeingDelivered?.length) {
      return firebase
        .firestore()
        .collectionGroup('shoppingListItems')
        .where(
          'shoppingListId',
          'in',
          listsBeingDelivered.map((c) => c.id),
        );
    }
  }, [listsBeingDelivered]);

  const { items, loading } = useShoppingListItems(shoppingListItemsRef);

  const itemsByUserId = useMemo(
    () => (items?.length ? groupBy(items, 'userId') : {}),
    [items],
  );

  const handleDone = () => {
    setIsConfirmCompleteOpen(true);
  };

  if (!shoppingListItemsRef || (!loading && (!items || !items.length))) {
    return (
      <Layout pageName="Deliver Groceries">
        <CreateShoppingListCTA />
      </Layout>
    );
  }

  return (
    <Layout className="deliver-groceries" pageName="Deliver Groceries">
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonButton>
              <IonIcon icon={mapOutline} />
              &nbsp;Directions
            </IonButton>
          </IonButtons> */}

          <IonTitle
            size="small"
            className="ion-title-stacked ion-title-stacked-top"
          >
            Deliver
          </IonTitle>
          <IonTitle className="ion-title-stacked">
            {listsBeingDelivered.map((l) => l.name).join(', ')}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleDone}>
              Done&nbsp;
              <IonIcon icon={checkmarkDoneCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonGrid>
        <IonRow>
          {Object.keys(itemsByUserId).length === 0 && (
            <>
              <IonCol size="12" sizeMd="6">
                <SkeletonCard />
              </IonCol>

              <IonCol size="12" sizeMd="6">
                <SkeletonCard />
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <SkeletonCard />
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <SkeletonCard />
              </IonCol>
            </>
          )}
          {Object.keys(itemsByUserId).length > 0 &&
            Object.keys(itemsByUserId).map((userId) => (
              <IonCol size="12" sizeMd="6" key={userId}>
                <UserDeliveryCard
                  user={usersById[userId]}
                  items={itemsByUserId[userId]}
                />
              </IonCol>
            ))}
        </IonRow>
      </IonGrid>

      <ConfirmCompleteShoppingListModal
        isOpen={isConfirmCompleteOpen}
        shoppingLists={listsBeingDelivered}
        onDismiss={() => setIsConfirmCompleteOpen(false)}
      />
    </Layout>
  );
};

interface IUserDeliveryCardProps {
  user: IUser;
  items: IShoppingListItem[];
}
const UserDeliveryCard: React.FC<IUserDeliveryCardProps> = ({
  user,
  items,
}) => {
  const currentUser = useCurrentUser();
  const [fulfilledItems, unfilledItems] = useMemo(
    () => partition(items.sort(), (item) => item.dibbedBy),
    [items],
  );

  if (!user) {
    return null;
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonItem>
          <UserAvatar user={user} />
          &nbsp;
          <IonLabel>
            <h2>{user.id === currentUser?.id ? 'You' : user.name}</h2>
            <p>{items.length} items</p>
          </IonLabel>
        </IonItem>
      </IonCardHeader>

      <IonCardContent>
        <IonList lines="none">
          {fulfilledItems.map((item) => (
            <IonItem key={item.id}>
              <IonIcon
                icon={checkmarkCircleOutline}
                slot="start"
                color="success"
              />
              <IonLabel>
                <h3>{item.name}</h3>
              </IonLabel>
            </IonItem>
          ))}
          {unfilledItems.map((item) => (
            <IonItem key={item.id}>
              <IonIcon icon={closeCircleOutline} slot="start" color="medium" />
              <IonLabel color="medium">
                <h3>{item.name}</h3>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

const SkeletonCard = ({ numItems = 3 }: { numItems?: number }) => {
  return (
    <IonCard>
      <IonCardHeader color="light">
        <IonAvatar color="light">
          <IonSkeletonText />
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: '75%' }} />
          </h2>
          <p>
            <IonSkeletonText animated style={{ width: '50%' }} />
          </p>
        </IonLabel>
      </IonCardHeader>

      <IonCardContent>
        <IonList>
          {Array(numItems)
            .fill(0)
            .map((_, itemNum) => (
              <IonItem key={itemNum}>
                <IonLabel>
                  <h2>
                    <IonSkeletonText animated />
                  </h2>
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default DeliverGroceriesTab;
