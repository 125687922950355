import {
  IShoppingList,
  IShoppingListItemData,
  IShoppingListItem,
  IShoppingListData,
} from '../../types/IShoppingList';
import { shoppingListItemFromDoc, shoppingListFromDoc } from './dataMappers';
import firebase, { firestore, analytics } from '../firebase';
import { IUser } from '../../types/IUser';
import { IGroup } from '../../types/IGroup';

type ShoppingListInsertData = Pick<
  IShoppingListData,
  'name' | 'visibleToGroupIds'
>;

const updateShoppingLists = async (
  lists: IShoppingList[],
  updateObj: Partial<IShoppingListData>,
) => {
  await firestore.runTransaction(async (tx) => {
    for (const list of lists) {
      tx.update(list.ref, updateObj);
    }

    return tx;
  });
};

const addShoppingList = async (data: ShoppingListInsertData, user: IUser) => {
  const _data = _shoppingListInsertData(data, user);
  const ref = await user.ref.collection('shoppingLists').add(_data);

  const doc = await ref.get();

  return shoppingListFromDoc(doc);
};

const duplicateShoppingList = async (
  srcList: IShoppingList,
  newData: { items?: IShoppingListItem[] } & ShoppingListInsertData,
  user: IUser,
) => {
  const { items, ...rest } = newData;
  const batch = firebase.firestore().batch();
  const newListData = _shoppingListInsertData(rest, user);

  const newListRef = user.ref.collection('shoppingLists').doc();

  batch.set(newListRef, {
    ...newListData,
    copiedFromShoppingListId: srcList.id,
  });

  if (items) {
    const shoppingListItemsRef = newListRef.collection('shoppingListItems');
    for (const item of items) {
      const data = _shoppingListItemInsertData(item, newListRef);
      const itemRef = shoppingListItemsRef.doc();
      batch.set(itemRef, data);
    }
  }

  await batch.commit();
};

const _shoppingListInsertData = (
  data: Pick<IShoppingListData, 'name' | 'visibleToGroupIds'>,
  user: IUser,
) => {
  return {
    ...data,
    visibleToGroupIds: data.visibleToGroupIds || [],
    status: 'active',
    shoppingStatus: 'planning',
    userId: user.id,
    createdAt: firebase.firestore.Timestamp.now(),
  };
};

const addShoppingListItem = async (
  shoppingList: IShoppingList,
  data: IShoppingListItemData,
): Promise<IShoppingListItem> => {
  const ref = await shoppingList.ref
    .collection('shoppingListItems')
    .add(_shoppingListItemInsertData(data, shoppingList));

  const doc = await ref.get();

  return shoppingListItemFromDoc(doc);
};

const _shoppingListItemInsertData = (
  data: IShoppingListItemData,
  shoppingList: { id: string },
) => {
  return {
    name: data.name,
    aisle: data.aisle,
    type: data.type || 'ingredient',
    refId: data.refId,
    refSource: data.refSource,
    image: data.image || '',
    userId: data.userId,
    shoppingListId: shoppingList.id,
    quantity: data.quantity,
    quantityType: data.quantityType,
    notes: data.notes,
    clonedFromItemId: data.clonedFromItemId || null,
    clonedFromItemPath: data.clonedFromItemPath || null,
  };
};

const removeShoppingListItem = async (
  shoppingList: IShoppingList,
  item: IShoppingListItem,
) => {
  await shoppingList.ref.collection('shoppingListItems').doc(item.id).delete();
};

const toggleShoppingListItemDib = async (
  item: IShoppingListItem,
  user: IUser,
) => {
  if (item.dibbedBy) {
    await item.ref.update({
      dibbedBy: null,
      dibbedOn: null,
    });
  } else {
    await item.ref.update({
      dibbedBy: user.id,
      dibbedOn: firebase.firestore.Timestamp.now(),
    });
  }
};

const addUserToGroup = async (user: IUser, groupOrId: IGroup | string) => {
  const groupId = typeof groupOrId === 'string' ? groupOrId : groupOrId.id;
  await user.ref.collection('memberOfGroups').doc(groupId).set({
    userId: user.id,
    groupId,
    joinedAt: new Date(),
  });

  analytics.logEvent('join_group', { groupId });
};

export {
  addShoppingListItem,
  removeShoppingListItem,
  toggleShoppingListItemDib,
  addUserToGroup,
  addShoppingList,
  duplicateShoppingList,
  updateShoppingLists,
};
