import { useEffect } from 'react';

import './Home.scss';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import { useHistory } from 'react-router';

const HomePage = () => {
  const history = useHistory();
  const currentUser = useRequireCurrentUser();

  useEffect(() => {
    if (currentUser && !currentUser.onboardingCompleted) {
      history.replace('/welcome');
    } else {
      // nuke this once we have some home page stuff
      history.replace('/groceries/shopping-lists');
    }
  }, [currentUser, history]);

  return null;
};

export default HomePage;
