import {
  IonItem,
  IonList,
  IonListHeader,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonAvatar,
  IonCheckbox,
  IonChip,
  IonText,
  IonIcon,
  IonSkeletonText,
  IonButton,
  IonBadge,
  IonCardContent,
  IonCard,
  IonButtons,
} from '@ionic/react';
import React, { useState, useMemo, useCallback } from 'react';
import _groupBy from 'lodash/groupBy';
import _uniqBy from 'lodash/uniqBy';
import _keyBy from 'lodash/keyBy';

import { IShoppingListItem } from '../../types/IShoppingList';

import { IUser } from '../../types/IUser';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import UserAvatar from './UserAvatar';
import {
  documentTextOutline,
  alertCircleOutline,
  caretDownOutline,
  caretForwardOutline,
  cartOutline,
  checkmarkDoneCircleOutline,
  basketOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons';
import useGroupMembership from '../hooks/useGroupMembership';
import useShoppingListItems from '../hooks/useShoppingListItems';
import { ISnapshot } from '../../types/Firebase';
import './ShoppingListItemsList.scss';

type IProps = {
  shoppingListItemsRef: ISnapshot;
  onItemClick?: (item: IShoppingListItem) => void;
  isItemClickable?: (item: IShoppingListItem) => boolean;
  groupBy?: 'aisle' | 'userId';
  subgroupBy?: 'shoppingListId';
  onItemToggle?: (item: IShoppingListItem, value: boolean) => void;
  checkedItems?: IShoppingListItem[];
  isItemChecked?: (item: IShoppingListItem) => boolean;
  isItemToggleable?: (item: IShoppingListItem) => boolean;
  isItemVisible?: (item: IShoppingListItem) => boolean;
  fallback?: React.ReactElement;
  collapsible?: boolean;
  suppressCount?: boolean;
};

type IUserMap = { [userId: string]: IUser };

const ShoppingListItemsList: React.FC<IProps> = ({
  shoppingListItemsRef,
  onItemClick,
  isItemClickable = () => false,
  groupBy = 'aisle',
  subgroupBy,
  onItemToggle,
  isItemChecked,
  isItemToggleable = () => true,
  checkedItems,
  isItemVisible,
  fallback,
  collapsible,
  suppressCount,
}) => {
  const { items: _items = [], loading } = useShoppingListItems(
    shoppingListItemsRef,
  );
  const items = useMemo(() => _uniqBy(_items, 'originalItemId'), [_items]);

  const currentUser = useRequireCurrentUser();
  const { users } = useGroupMembership();

  const userMap: IUserMap = useMemo(
    () =>
      users.concat(currentUser).reduce(
        (acc, user) => ({
          ...acc,
          [user.id]: user,
        }),
        {},
      ),
    [currentUser, users],
  );

  const itemsByGroup = useMemo(() => {
    return _groupBy(
      items.filter((item) => !!userMap[item.userId]),
      groupBy,
    );
  }, [items, groupBy, userMap]);

  const checkedItemMap: {
    [itemId: string]: boolean | null;
  } = useMemo(() => {
    if (checkedItems) {
      return checkedItems.reduce(
        (acc, item) => ({
          ...acc,
          [item.originalItemId]: true,
        }),
        {},
      );
    } else if (isItemChecked) {
      return items.reduce(
        (acc, item) => ({
          ...acc,
          [item.originalItemId]: isItemChecked(item),
        }),
        {},
      );
    } else {
      return {};
    }
  }, [checkedItems, isItemChecked, items]);

  if (loading) {
    return <SkeletonList />;
  }

  return (
    <>
      <IonList>
        {!suppressCount && (
          <IonListHeader>
            <IonLabel>
              Items ({Object.values(checkedItemMap).filter(Boolean).length} /{' '}
              {items.length})
            </IonLabel>
          </IonListHeader>
        )}
        {Object.keys(itemsByGroup)
          .sort((a, b) => a.localeCompare(b))
          .map((groupKey) => (
            <ListGroup
              key={groupKey}
              groupBy={groupBy}
              subgroupBy={subgroupBy}
              groupKey={groupKey}
              items={itemsByGroup[groupKey]}
              isItemClickable={isItemClickable}
              isItemToggleable={isItemToggleable}
              isItemVisible={isItemVisible}
              onItemToggle={onItemToggle}
              onItemClick={onItemClick}
              userMap={userMap}
              checkedItemMap={checkedItemMap}
              currentUser={currentUser}
              collapsible={collapsible}
            />
          ))}
      </IonList>
      {items?.length === 0 &&
        (fallback || (
          <IonCard>
            <IonCardContent>
              <IonLabel>There's nothing here yet.</IonLabel>
            </IonCardContent>
          </IonCard>
        ))}
    </>
  );
};

interface IListGroup {
  groupKey: string;
  groupBy: 'aisle' | 'userId';
  subgroupBy?: 'shoppingListId';
  items: IShoppingListItem[];
  currentUser: IUser;
  onItemClick?: (item: IShoppingListItem) => void;
  isItemClickable: (item: IShoppingListItem) => boolean;
  onItemToggle?: (item: IShoppingListItem, value: boolean) => void;
  checkedItems?: IShoppingListItem[];
  isItemChecked?: (item: IShoppingListItem) => boolean;
  isItemToggleable: (item: IShoppingListItem) => boolean;
  isItemVisible?: (item: IShoppingListItem) => boolean;
  userMap: { [userId: string]: IUser };
  checkedItemMap: { [id: string]: boolean | null };
  collapsible?: boolean;
}

const ListGroup: React.FC<IListGroup> = ({
  groupKey,
  groupBy,
  subgroupBy = '',
  items: _items,
  currentUser,
  checkedItemMap,
  userMap,
  onItemClick,
  onItemToggle,
  isItemToggleable,
  isItemClickable,
  isItemVisible,
  collapsible,
}) => {
  const isCollapsible = useMemo(
    () => collapsible !== false && groupBy === 'userId',
    [collapsible, groupBy],
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(!isCollapsible);
  const { shoppingLists } = useGroupMembership();

  const handleItemToggle = useCallback(
    (e: CustomEvent<any>, item: IShoppingListItem) => {
      if (onItemToggle) {
        onItemToggle(item, e.detail.checked);
      }
    },
    [onItemToggle],
  );

  const isItemToggleEnabled = useMemo(() => onItemToggle, [onItemToggle]);
  const items = useMemo(
    () =>
      _groupBy(
        _items
          .filter((i) => !isItemVisible || isItemVisible(i))
          .sort((a, b) => a.name.localeCompare(b.name)),
        subgroupBy,
      ),
    [_items, isItemVisible, subgroupBy],
  );

  const shoppingListsById = useMemo(() => _keyBy(shoppingLists, 'id'), [
    shoppingLists,
  ]);

  if (Object.keys(items).length === 0) {
    return null;
  }

  return (
    <IonItemGroup>
      <GroupHeader
        groupKey={groupKey}
        groupBy={groupBy}
        userMap={userMap}
        expanded={!!isExpanded}
        collapsible={isCollapsible}
        onExpandClick={() => setIsExpanded(!isExpanded)}
      />

      <div style={{ display: isExpanded ? 'block' : 'none' }}>
        {Object.keys(items).map((subgroupKey) => (
          <React.Fragment key={subgroupKey}>
            {subgroupKey && subgroupBy && (
              <IonItemDivider sticky color="light">
                {shoppingListsById[subgroupKey]?.name || 'Unknown list'}
              </IonItemDivider>
            )}

            {items[subgroupKey].map((item) => (
              <ListItem
                key={item.id}
                item={item}
                currentUser={currentUser}
                onClick={() => (onItemClick ? onItemClick(item) : undefined)}
                isClickable={isItemClickable}
                onToggle={isItemToggleEnabled && handleItemToggle}
                isToggleable={isItemToggleable(item)}
                requestingUser={userMap[item.userId]}
                fulfillingUser={userMap[item.dibbedBy || '']}
                checked={!!checkedItemMap[item.originalItemId]}
                userMap={userMap}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </IonItemGroup>
  );
};

interface IGroupHeaderProps {
  groupBy: 'userId' | 'aisle';
  groupKey: string;
  userMap: { [userId: string]: IUser };
  onExpandClick: () => void;
  collapsible: boolean;
  expanded: boolean;
}
const GroupHeader: React.FC<IGroupHeaderProps> = ({
  groupBy,
  groupKey,
  userMap,
  onExpandClick,
  collapsible,
  expanded,
}) => {
  let label;

  if (groupBy === 'aisle') {
    // tslint:disable-next-line
    if (groupKey === 'null') {
      label = <IonLabel>Other</IonLabel>;
    } else {
      label = <IonLabel>{groupKey}</IonLabel>;
    }
  } else if (groupBy === 'userId') {
    const user = userMap[groupKey];
    label = (
      <>
        <UserAvatar user={user} slot="start" size={24} />
        <IonLabel>{user?.name}</IonLabel>
      </>
    );
  } else {
    label = <IonLabel>???</IonLabel>;
  }

  return (
    <>
      <IonItemDivider
        sticky
        color="medium"
        onClick={() => collapsible && onExpandClick()}
      >
        {label}
        {collapsible && (
          <IonButtons slot="end" onClick={onExpandClick}>
            <IonButton slot="end">
              <IonIcon
                icon={expanded ? caretDownOutline : caretForwardOutline}
              />
            </IonButton>
          </IonButtons>
        )}
      </IonItemDivider>
    </>
  );
};
interface IListItemProps {
  item: IShoppingListItem;
  onClick?: (e: React.MouseEvent<any>, item: IShoppingListItem) => void;
  isClickable: (item: IShoppingListItem) => boolean;
  onToggle?: (e: CustomEvent<any>, item: IShoppingListItem) => void;
  isToggleable?: boolean;
  currentUser: IUser;
  checked?: boolean;
  requestingUser: IUser;
  fulfillingUser?: IUser | null;
  userMap: IUserMap;
}
const ListItem: React.FC<IListItemProps> = ({
  item,
  onClick,
  isClickable,
  onToggle,
  isToggleable = () => !!onToggle,
  checked,
  requestingUser,
  fulfillingUser,
  currentUser,
  userMap,
}) => {
  const handleClick = useCallback(
    (e) => {
      if (onClick && isClickable(item)) {
        onClick(e, item);
      }
    },
    [isClickable, item, onClick],
  );

  return (
    <IonItem
      key={item.id}
      lines="full"
      onClick={handleClick}
      button={!!onClick && isClickable(item)}
      detail={false}
    >
      <IonAvatar slot="start">
        <img src={item.image} alt={item.name} />
      </IonAvatar>
      <IonLabel>
        <h3 style={{ textTransform: 'capitalize' }}>{item.name}</h3>

        <div className="ion-hide-print">
          {item.notes && (
            <IonBadge
              slot="end"
              className="ion-float-right ion-hide-print"
              color="light"
            >
              <IonIcon icon={documentTextOutline} />
            </IonBadge>
          )}

          {!item.deletedOn && (
            <IonBadge color="light" slot="end" className="quantity-badge">
              {item.quantity || 1} {item.quantityType || 'each'}
            </IonBadge>
          )}
        </div>

        <div>
          <AttributionLine
            requestingUser={requestingUser}
            fulfillingUser={fulfillingUser}
            item={item}
            userMap={userMap}
          />
        </div>

        {item.notes && (
          <p className="ion-show-print" style={{ fontSize: 12 }}>
            <IonIcon
              icon={documentTextOutline}
              slot="start"
              style={{ margin: 4 }}
              color="medium"
              className="ion-float-left"
            />
            <IonLabel color="medium">{item.notes}</IonLabel>
          </p>
        )}
      </IonLabel>

      {isToggleable && onToggle && (
        <IonCheckbox
          slot="end"
          checked={checked}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          disabled={!!item.deletedOn}
          onIonChange={(e) => onToggle(e, item)}
        />
      )}
    </IonItem>
  );
};

const AttributionLine = ({
  requestingUser,
  fulfillingUser,
  item,
  userMap,
}: {
  requestingUser: IUser;
  fulfillingUser?: IUser | null;
  item: IShoppingListItem;
  slot?: string;
  userMap: { [userId: string]: IUser };
}) => {
  const currentUser = useRequireCurrentUser();
  const usersWhoHaveCloned = useMemo(
    () =>
      (item.clonedByUserIds || [])
        .map((userId) => userMap[userId])
        .filter(Boolean),
    [item, userMap],
  );
  const dibbedBySomeoneElse = useMemo(
    () => fulfillingUser && fulfillingUser.id !== currentUser.id,
    [fulfillingUser, currentUser],
  );

  if (
    requestingUser?.id === currentUser.id &&
    fulfillingUser?.id === currentUser.id
  ) {
    return null;
  }

  if (item.deletedOn) {
    return (
      <IonBadge className="dib-alert">
        <IonIcon icon={alertCircleOutline} color="warning" />
        <IonText color="danger">This item was deleted by its requester</IonText>
      </IonBadge>
    );
  }

  if (dibbedBySomeoneElse) {
    return (
      <IonBadge className="dib-alert">
        <IonIcon icon={checkmarkCircleOutline} color="success" />
        <IonText color="success">
          {fulfillingUser?.name || 'Another user'} has already grabbed this item
          for you.
        </IonText>
      </IonBadge>
    );
  }

  return (
    <div className="attribution-line">
      {requestingUser?.id !== currentUser.id && (
        <>
          <IonIcon icon={basketOutline} className="ion-icon-inline-text" />
          <IonText color="medium">requested by</IonText>
          <IonChip outline className="user-chip">
            {requestingUser.id === currentUser.id ? 'You' : requestingUser.name}
          </IonChip>
        </>
      )}

      {fulfillingUser && requestingUser.id === currentUser.id && (
        <>
          <IonIcon
            icon={checkmarkDoneCircleOutline}
            className="ion-icon-inline-text"
          />
          <IonText color="medium">filled by</IonText>
          <IonChip outline className="user-chip">
            {fulfillingUser.id === currentUser.id ? 'You' : fulfillingUser.name}
          </IonChip>
        </>
      )}

      {requestingUser?.id === currentUser.id &&
        usersWhoHaveCloned.map((user) => (
          <React.Fragment key={user.id}>
            <IonChip outline className="user-chip">
              {user.name}
            </IonChip>
            <IonText color="medium">is planning to get this</IonText>
            <IonIcon icon={cartOutline} className="ion-icon-inline-text" />
          </React.Fragment>
        ))}
    </div>
  );
};

const SkeletonList = ({
  numGroups = 3,
  numItems = 3,
}: {
  numGroups?: number;
  numItems?: number;
}) => {
  return (
    <>
      {Array(numGroups)
        .fill(0)
        .map((_, groupNum) => (
          <IonItemGroup key={groupNum}>
            <IonItemDivider color="light">
              <IonLabel style={{ width: '97%' }}>
                <IonSkeletonText animated />
              </IonLabel>
            </IonItemDivider>
            {Array(numItems)
              .fill(0)
              .map((_, itemNum) => (
                <IonItem key={itemNum}>
                  <IonAvatar color="light">
                    <IonSkeletonText />
                  </IonAvatar>
                  <IonLabel>
                    <h2>
                      <IonSkeletonText animated />
                    </h2>
                    <p>
                      <IonSkeletonText animated />
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
          </IonItemGroup>
        ))}
    </>
  );
};

export default ShoppingListItemsList;
