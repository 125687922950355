import React from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';

interface IProps {
  text: string;
  arrowRotation?: number;
  arrowPosition?: 'top' | 'bottom';
  arrowFlip?: boolean;
  style?: IonicReactProps['style'];
}
const GetStartedCTA: React.FC<IProps> = ({
  text,
  arrowRotation = 0,
  arrowPosition = 'bottom',
  arrowFlip,
  style = {},
}) => {
  const arrow = (
    <IonRow>
      <IonCol>
        <img
          src="/assets/add-list-arrow.png"
          alt="add pointer"
          style={{
            transform: `rotate(${arrowRotation}deg) scaleX(${
              arrowFlip ? -1 : 1
            })`,
          }}
        />
      </IonCol>
    </IonRow>
  );

  const _style = {
    textAlign: 'center',
    ...style,
  };

  return (
    <IonGrid style={_style}>
      {arrowPosition === 'top' && arrow}
      <IonRow>
        <IonCol>
          <h3>{text}</h3>
        </IonCol>
      </IonRow>
      {arrowPosition === 'bottom' && arrow}
    </IonGrid>
  );
};

export default GetStartedCTA;
