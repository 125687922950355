import React, { useState, useRef } from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonCard,
  IonCardSubtitle,
  IonLabel,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLoading,
  IonInput,
  IonItem,
} from '@ionic/react';
import { pencilSharp, saveOutline, closeOutline } from 'ionicons/icons';
import AvatarSelector from '../components/AvatarSelector';

import { IUser } from '../../types/IUser';
import UserAvatar from '../components/UserAvatar';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import useSaveAvatar from '../hooks/useSaveAvatar';

import Layout from '../layouts/Layout';

interface IProps {
  user: IUser;
}

const Profile: React.FC<IProps> = () => {
  const currentUser = useRequireCurrentUser();
  const [editMode, setEditMode] = useState<boolean>(false);
  const avatarSelector = useRef();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>(currentUser.name);
  const { saveAvatar } = useSaveAvatar();

  const handleSave = async () => {
    setIsLoading(true);
    const userUpdateData: { photoURL?: string; name?: string } = {};

    // @ts-ignore
    if (avatarSelector.current?.isDirty()) {
      // @ts-ignore
      const blob: Blob = await avatarSelector.current?.getImageAsBlob();
      await saveAvatar(blob);
    }

    if (name && name !== currentUser.name) {
      userUpdateData.name = name;
    }

    if (userUpdateData.name) {
      await currentUser.ref.update(userUpdateData);
    }
    setEditMode(false);
    setIsLoading(false);
  };

  return (
    <Layout className="profile" pageName="Profile">
      <IonCard className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="end">
            {!editMode && (
              <IonButton onClick={() => setEditMode(true)}>
                <IonIcon icon={pencilSharp} />
                &nbsp;
                <IonLabel>Edit</IonLabel>
              </IonButton>
            )}
            {editMode && (
              <>
                <IonButton onClick={() => setEditMode(false)}>
                  <IonIcon icon={closeOutline} />
                  &nbsp;
                  <IonLabel>Cancel</IonLabel>
                </IonButton>
                <IonButton onClick={handleSave}>
                  <IonIcon icon={saveOutline} />
                  &nbsp;
                  <IonLabel>Save</IonLabel>
                </IonButton>
              </>
            )}
          </IonButtons>
        </IonToolbar>
        <div>
          {editMode && (
            <AvatarSelector
              image={currentUser.photoURL}
              ref={(ref: any) => (avatarSelector.current = ref)}
            />
          )}

          {!editMode && (
            <UserAvatar
              user={currentUser}
              size={256}
              style={{ display: 'inline-block' }}
            />
          )}
        </div>
        <IonCardHeader>
          <IonCardTitle>
            {!editMode && currentUser.name}

            {editMode && (
              <IonItem>
                <IonLabel position="floating">Name</IonLabel>
                <IonInput
                  value={name}
                  onIonChange={(e) => setName(e.detail.value || '')}
                  debounce={100}
                  color="dark"
                  required
                />
              </IonItem>
            )}
          </IonCardTitle>
          <IonCardSubtitle></IonCardSubtitle>
        </IonCardHeader>
      </IonCard>

      <IonLoading isOpen={isLoading} />
    </Layout>
  );
};

export default Profile;
