import React, { useEffect } from 'react';
import {
  IonPage,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonTitle,
} from '@ionic/react';
import Header from '../components/Header';
import { refreshOutline } from 'ionicons/icons';
import './Layout.scss';
import ReactDOM from 'react-dom';
import { config } from '../firebase';
import useCloudMessaging from '../hooks/useCloudMessaging';
import useAnalytics from '../hooks/useAnalytics';

interface IProps {
  pageName: string;
  title?: string;
  canGoBack?: string;
  className?: string;
  id?: string;
  printMode?: boolean;
  hideAppMenu?: boolean;
  hideHeader?: boolean;
}
const Layout: React.FC<IProps> = ({
  pageName,
  children,
  title,
  canGoBack,
  className = '',
  id,
  printMode,
  hideAppMenu,
  hideHeader,
}) => {
  const printerNode = document.getElementById('print-root');

  useEffect(() => {
    const body: HTMLBodyElement | null = document.querySelector('body');
    if (printMode) {
      body?.classList?.add('print');
    } else {
      body?.classList?.remove('print');
    }
  }, [printMode]);

  useEffect(() => {
    if (pageName) {
      document.title = `${document.title.split('-')[0]} - ${pageName}`;
    }
  }, [pageName]);

  useAnalytics({ screenName: pageName });

  if (printerNode && printMode) {
    return ReactDOM.createPortal(children, printerNode);
  }
  return (
    <IonPage className={className} id={id} key={pageName}>
      <EnvHeader />
      {!hideHeader && (
        <Header title={title} canGoBack={canGoBack} hideAppMenu={hideAppMenu} />
      )}
      <IonContent className="layout-content" key={pageName}>
        <IonRefresher
          slot="fixed"
          onIonRefresh={() => window.location.reload()}
          pullFactor={0.5}
          pullMin={100}
        >
          <IonRefresherContent
            pullingIcon={refreshOutline}
            pullingText="Pull to refresh"
            // refreshingSpinner="circles"
            // refreshingText="Refreshing..."
            style={{ backgroundColor: '#fff' }}
          />
        </IonRefresher>
        {children}
      </IonContent>
    </IonPage>
  );
};

const EnvHeader = () => {
  const { token } = useCloudMessaging();
  const location = window?.location.toString() || '';
  const appEnv =
    location.includes(':3000') || location.includes('dev') ? 'dev' : 'prod';
  const firebaseEnv = config.projectId.includes('-dev') ? 'dev' : 'prod';
  const conflict = appEnv !== firebaseEnv;
  if (appEnv === 'dev' || conflict) {
    return (
      <IonHeader
        className={`ion-text-center env-header ${conflict ? 'danger' : ''}`}
      >
        <IonTitle>
          App Env: {appEnv}, FirebaseEnv: {firebaseEnv}
          <p>{token}</p>
        </IonTitle>
      </IonHeader>
    );
  }

  return null;
};
export default Layout;
