import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { IUser } from '../../types/IUser';
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonSlide,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonSlides,
} from '@ionic/react';
import { chevronForward, addOutline } from 'ionicons/icons';
import useSaveAvatar from '../hooks/useSaveAvatar';
import AvatarSelector from '../components/AvatarSelector';
import { IGroup } from '../../types/IGroup';
import { AddEditGroupModal } from './GroupsTab';
import { useCurrentUser } from '../hooks';
import firebase, { analytics } from '../firebase';
import Layout from '../layouts/Layout';
import { useHistory } from 'react-router';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import { addUserToGroup } from '../helpers/dataMutations';

interface ISlideProps {
  onNext: () => void;
  currentUser: IUser;
}

interface INextSlideButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const NextSlideButton: React.FC<INextSlideButtonProps> = ({
  text,
  onClick,
  disabled,
}) => (
  <IonToolbar>
    <IonButtons style={{ display: 'block' }} slot="end">
      <IonButton onClick={onClick} disabled={disabled}>
        {text}
        <IonIcon icon={chevronForward} />
      </IonButton>
    </IonButtons>
  </IonToolbar>
);

const OnboardSlideWelcome: React.FC<ISlideProps> = ({ onNext }) => {
  return (
    <IonSlide>
      <NextSlideButton text="Get started" onClick={onNext} />
      <h2>Welcome to Backyard Collective!</h2>
      <p>
        Backyard Collective help you and your neighbors limit your trips to the
        store for groceries and other household necessities.
      </p>
      <p>
        Over time, Backyard Collective will evolve to allow you to share other
        household items with your neighbors and build your local community
        stockpile.
      </p>
    </IonSlide>
  );
};

const OnboardSlideAvatar: React.FC<ISlideProps> = ({ onNext, currentUser }) => {
  const { saveAvatar } = useSaveAvatar();
  const avatarSelector = useRef<any | null>();
  const [isAvatarUpdated, setIsAvatarUpdated] = useState<boolean>(
    !!avatarSelector.current?.isDirty(),
  );

  const valid = useMemo(() => isAvatarUpdated || currentUser.photoURL, [
    currentUser,
    isAvatarUpdated,
  ]);

  const handleNext = async () => {
    if (avatarSelector.current?.isDirty()) {
      const blob = await avatarSelector.current?.getImageAsBlob();
      await saveAvatar(blob);

      onNext();
    } else if (currentUser.photoURL) {
      onNext();
    }
  };

  return (
    <IonSlide>
      <NextSlideButton text="Next" onClick={handleNext} disabled={!valid} />
      <h2>Upload a photo</h2>
      <p>Only members of your groups will be able to access your profile.</p>
      <IonGrid>
        <IonRow>
          <IonCol>
            <AvatarSelector
              image={currentUser.photoURL}
              hideTools
              ref={(ref: any) => (avatarSelector.current = ref)}
              onAvatarUpdated={() => setIsAvatarUpdated(true)}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonSlide>
  );
};

const OnboardSlideProfile: React.FC<ISlideProps> = ({
  onNext,
  currentUser,
}) => {
  const [name, setName] = useState<string>(currentUser.name);

  const handleNext = async () => {
    if (name) {
      await currentUser.ref.update({ name });
      onNext();
    }
  };

  return (
    <IonSlide className="avatar-slide">
      <NextSlideButton text="Update" onClick={handleNext} disabled={!name} />
      <h2>Set up your profile</h2>
      <p>Only members of your groups will be able to access your profile.</p>
      <IonGrid>
        <IonRow>
          <IonCol size="9" push="1" sizeLg="6" pushLg="3">
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput
                value={name}
                onIonChange={(e) => setName(e.detail.value || '')}
                debounce={100}
                color="dark"
                required
              />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonSlide>
  );
};

const OnboardSlideGroup: React.FC<ISlideProps> = ({ onNext, currentUser }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const isCodeValid = useMemo(() => code.length > 1, [code]);

  const handleJoin = useCallback(() => {
    addUserToGroup(currentUser, code).then(onNext);
  }, [currentUser, code, onNext]);

  const handleAddGroupDismiss = (_: any, group?: IGroup) => {
    setIsAddModalOpen(false);
    if (group) {
      onNext();
    }
  };

  return (
    <IonSlide>
      <NextSlideButton text="Not now" onClick={onNext} />
      <h2>Join (or start!) a group</h2>

      <IonGrid>
        <IonRow>
          <IonCol size="9" push="1" sizeLg="6" pushLg="3">
            <p>
              Groups are at the center of Backyard Collective. Each group you
              belong to will allow you to share groceries and other items.
            </p>

            <p style={{ marginTop: 20 }}>
              You can join an existing group now if you've been invited
              <IonItem>
                <IonInput
                  placeholder="Code"
                  value={code}
                  onIonChange={(e) => setCode(e.detail.value || '')}
                />
                <IonButtons>
                  <IonButton disabled={!isCodeValid} onClick={handleJoin}>
                    Join
                  </IonButton>
                </IonButtons>
              </IonItem>
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="9" push="1" sizeLg="6" pushLg="3">
            <p style={{ marginTop: 40 }}>
              Or start a new group for your family, friends or neighborhood.
              <IonButtons
                style={{ textAlign: 'center', display: 'block', marginTop: 20 }}
              >
                <IonButton
                  fill="outline"
                  onClick={() => setIsAddModalOpen(true)}
                >
                  <IonIcon icon={addOutline} /> New Group
                </IonButton>
              </IonButtons>
            </p>
          </IonCol>
        </IonRow>
      </IonGrid>
      <AddEditGroupModal
        isOpen={isAddModalOpen}
        onDismiss={handleAddGroupDismiss}
      />
    </IonSlide>
  );
};
const OnboardSlides = () => {
  const currentUser = useCurrentUser();
  const swiper = useRef<HTMLIonSlidesElement | null>();
  const history = useHistory();

  useEffect(() => {
    analytics.logEvent('tutorial_begin');
  }, []);
  if (!currentUser) {
    return null;
  }

  const handleNextSlide = () => swiper.current?.slideNext();

  const handleFinished = () => {
    currentUser.ref
      .update({
        onboardingCompleted: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        analytics.logEvent('tutorial_finished');
      })
      .then(() => {
        history.replace('/');
      });
  };

  return (
    <IonSlides pager ref={(ref) => (swiper.current = ref)}>
      <OnboardSlideWelcome currentUser={currentUser} onNext={handleNextSlide} />
      <OnboardSlideAvatar currentUser={currentUser} onNext={handleNextSlide} />
      <OnboardSlideProfile currentUser={currentUser} onNext={handleNextSlide} />
      <OnboardSlideGroup currentUser={currentUser} onNext={handleFinished} />
    </IonSlides>
  );
};

const OnboardingPage = () => {
  const currentUser = useRequireCurrentUser();
  const history = useHistory();

  useEffect(() => {
    if (currentUser && currentUser.onboardingCompleted) {
      history.replace('/');
    }
  }, [currentUser, history]);
  return (
    <Layout className="onboarding" hideAppMenu pageName="Onboarding">
      <OnboardSlides />
    </Layout>
  );
};

export default OnboardingPage;
