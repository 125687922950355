import React, { ReactElement, useState, useEffect } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonFooter,
  IonLoading,
} from '@ionic/react';
import useAnalytics from '../hooks/useAnalytics';

interface IHeaderButtonProps {
  label: string | React.ReactNode;
  color?: string;
  onClick?: (e: React.MouseEvent<any, any>) => void;
}
interface IHeaderProps {
  buttons: IHeaderButtonProps[];
  onDismiss: (e: any) => void;
  title: string;
  subtitle?: string;
  valid?: boolean;
}
interface IProps {
  valid?: boolean;
  isOpen: boolean;
  buttons: IHeaderButtonProps[];
  onDismiss: (e: any) => void;
  title: string;
  subtitle?: string;

  children: React.ReactChild;
  footer?: ReactElement;
  modalName: string;
}

const ActionModalHeader: React.FC<IHeaderProps> = ({
  buttons = [],
  title,
  subtitle,
  onDismiss,
  valid,
}) => {
  const [primaryButton, secondaryButton] = buttons;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePrimaryClick = (e: React.MouseEvent) => {
    if (primaryButton?.onClick) {
      setIsLoading(true);
      primaryButton.onClick(e);
    }
  };

  return (
    <>
      <IonHeader style={{ zIndex: 1000 }}>
        <IonToolbar>
          {primaryButton && (
            <IonButtons slot="primary">
              <IonButton
                disabled={valid === false}
                color={primaryButton.color || 'primary'}
                onClick={handlePrimaryClick}
              >
                {primaryButton.label}
              </IonButton>
            </IonButtons>
          )}
          {subtitle && (
            <IonTitle
              size="small"
              className="ion-title-stacked ion-title-stacked-top"
            >
              {subtitle}
            </IonTitle>
          )}
          <IonTitle className={subtitle ? 'ion-title-stacked' : ''}>
            {title}
          </IonTitle>

          {secondaryButton && (
            <IonButtons slot="secondary">
              <IonButton
                color={secondaryButton.color || 'secondary'}
                onClick={secondaryButton.onClick || onDismiss}
              >
                {secondaryButton.label || 'Close'}
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={isLoading} />
    </>
  );
};
const ActionModal: React.FC<IProps> = ({
  isOpen,
  buttons,
  title,
  subtitle,
  onDismiss,
  children,
  valid,
  footer,
  modalName,
}) => {
  const { logScreenView } = useAnalytics();

  useEffect(() => {
    if (isOpen) {
      logScreenView(modalName);
    }
  }, [isOpen, logScreenView, modalName]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
      <ActionModalHeader
        buttons={buttons}
        title={title}
        subtitle={subtitle}
        onDismiss={onDismiss}
        valid={valid}
      />
      <IonContent fullscreen className="ion-padding">
        {children}
      </IonContent>

      {footer && <IonFooter>{footer}</IonFooter>}
    </IonModal>
  );
};

export default ActionModal;
