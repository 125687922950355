import React, {
  useState,
  useMemo,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from 'react';
import {
  IonSearchbar,
  IonLabel,
  IonToolbar,
  IonList,
  IonItem,
  IonAvatar,
  IonCheckbox,
  IonSegment,
  IonSegmentButton,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonIcon,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import uniqBy from 'lodash/uniqBy';

import spoonacularImageSrc from '../helpers/spoonacularImageSrc';
import {
  useAutocompleteSearch,
  IUnifiedAutocompleteItem,
  IUnifiedFoodItem,
} from '../hooks/useSpoonacular';
import { createOutline, addCircleOutline } from 'ionicons/icons';
import ActionModal from './ActionModal';
import { AISLES } from '../constants';
import { useCurrentUser } from '../hooks';
import { foodItemFromDoc } from '../helpers/dataMappers';

import './ProductSearch.css';
import bestMatchSort from '../helpers/bestMatchSort';

interface IProps {
  onSelectItem?: (result: IUnifiedAutocompleteItem) => void;
  onDeselectItem?: (result: IUnifiedAutocompleteItem) => void;
  onClickItem?: (result: IUnifiedAutocompleteItem) => void;
  selectedItemIds: string[];
}

const ProductSearch: React.FC<IProps> = ({
  onSelectItem,
  onDeselectItem,
  onClickItem,
  selectedItemIds,
}) => {
  const currentUser = useCurrentUser();

  const [
    { autoCompleteSearchQuery, productSearchQuery, shouldShowAutoCompleteList },
    autoCompleteDispatch,
  ] = useReducer(autoCompleteReducer, {
    productSearchQuery: '',
    autoCompleteSearchQuery: '',
    shouldShowAutoCompleteList: true,
  });

  const [visibleSegment, setVisibleSegment] = useState<
    'product' | 'ingredient' | 'custom'
  >('custom');

  const [customItems, setCustomItems] = useState<IUnifiedFoodItem[]>([]);

  const [isCreateItemModalOpen, setIsCreateItemModalOpen] = useState<boolean>(
    false,
  );

  const autocompleteDismissTimeoutRef = useRef<number>();

  const { data: rawAutoCompleteResults = [] } = useAutocompleteSearch(
    productSearchQuery ? '' : autoCompleteSearchQuery,
    {
      type: 'all',
    },
  );

  const { data: spoonacularSearchResults = [] } = useAutocompleteSearch(
    productSearchQuery,
    {
      type: 'all',
      detailed: true,
    },
  );

  // sort the results, ensuring anything that startsWith the query is at the front
  const autoCompleteResults = useMemo(
    () =>
      bestMatchSort(rawAutoCompleteResults, autoCompleteSearchQuery, 'name'),
    [autoCompleteSearchQuery, rawAutoCompleteResults],
  );

  // load the custom items for the user
  useEffect(() => {
    return currentUser?.ref.collection('customItems').onSnapshot((snap) => {
      const _items = snap.docs.map((doc) => foodItemFromDoc(doc));
      setCustomItems(_items);
    });
  }, [currentUser]);

  // triggered when the autocomplete query is altered
  const handleSearchQueryChange = (e: CustomEvent<any>) => {
    const { value } = e.detail;
    if (value && value !== productSearchQuery) {
      autoCompleteDispatch({ type: 'setAutoCompleteSearchQuery', value });
    }
  };

  const handleAutocompleteSelect = useCallback(
    (query: string) => {
      const productsForQuery = rawAutoCompleteResults.filter(
        (r) => r.type === 'product' && r.name === query,
      );

      // optimization for if the autocomplete contains an exact result (e.g. nature's promise barramundi)
      const value =
        productsForQuery.length === 1
          ? `__id=${productsForQuery[0].id}`
          : query;

      autoCompleteDispatch({ type: 'setProductSearchQuery', value });
    },
    [rawAutoCompleteResults],
  );

  // blurring the search field sets a timeout to hide the autocomplete...
  // we need a way to disable it if the blur was caused by interacting with the autocomplete list
  const clearAutocompleteDismissTimeout = () => {
    if (autocompleteDismissTimeoutRef.current) {
      clearTimeout(autocompleteDismissTimeoutRef.current);
    }
  };

  const filteredCustomItems = useMemo(
    () =>
      customItems.filter(
        (i) =>
          !productSearchQuery ||
          new RegExp(productSearchQuery, 'i').test(i.name),
      ),
    [customItems, productSearchQuery],
  );

  const visibleResults = useMemo(() => {
    const visibleUnsortedResults = uniqBy(spoonacularSearchResults, 'name')
      .concat(filteredCustomItems)
      .filter(
        (result) =>
          (visibleSegment === 'custom' && result.source === 'custom') ||
          result.type === visibleSegment,
      );

    return bestMatchSort(visibleUnsortedResults, productSearchQuery, 'name');
  }, [
    spoonacularSearchResults,
    filteredCustomItems,
    productSearchQuery,
    visibleSegment,
  ]);

  const countsByType = useMemo(
    () => ({
      custom: filteredCustomItems.length,
      products: spoonacularSearchResults.filter((r) => r.type === 'product')
        .length,
      ingredients: spoonacularSearchResults.filter(
        (r) => r.type === 'ingredient',
      ).length,
    }),
    [spoonacularSearchResults, filteredCustomItems],
  );

  // auto select segment with search results
  useEffect(() => {
    if (countsByType.custom > 0) {
      setVisibleSegment('custom');
    } else if (countsByType.ingredients > 0) {
      setVisibleSegment('ingredient');
    } else if (countsByType.products > 0) {
      setVisibleSegment('product');
    } else {
      setVisibleSegment('custom');
    }
  }, [countsByType.custom, countsByType.ingredients, countsByType.products]);

  return (
    <>
      <section>
        <IonToolbar>
          <IonSearchbar
            onIonChange={handleSearchQueryChange}
            onIonClear={() =>
              autoCompleteDispatch({
                type: 'setProductSearchQuery',
                value: '',
              })
            }
            onIonBlur={() => {
              autocompleteDismissTimeoutRef.current = window.setTimeout(() => {
                autoCompleteDispatch({ type: 'hideAutoCompleteList' }); // give the autocomplete button a chance to fire first
              }, 200);
            }}
            onIonFocus={() =>
              autoCompleteDispatch({ type: 'showAutoCompleteList' })
            }
            debounce={300}
          />
        </IonToolbar>

        {/* autocomplete dropdown */}
        {shouldShowAutoCompleteList && autoCompleteResults.length > 0 && (
          <IonGrid
            className="autocomplete-dropdown"
            onMouseEnter={clearAutocompleteDismissTimeout}
            onScroll={clearAutocompleteDismissTimeout}
          >
            <IonRow>
              <IonCol>
                <IonList>
                  {autoCompleteResults.map((result) => (
                    <IonItem
                      key={result.id}
                      onClick={() => {
                        handleAutocompleteSelect(result.name);
                      }}
                      button
                    >
                      <IonLabel>{result.name}</IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {/* end autocomplate dropdown */}

        {/* product type segment */}
        <IonToolbar>
          <IonSegment
            value={visibleSegment}
            onIonChange={(e) =>
              setVisibleSegment(
                e.detail.value as 'ingredient' | 'product' | 'custom',
              )
            }
          >
            <IonSegmentButton value="custom" style={{ fontSize: 12 }}>
              My Items ({countsByType.custom})
            </IonSegmentButton>
            <IonSegmentButton value="ingredient" style={{ fontSize: 12 }}>
              Ingredients ({countsByType.ingredients})
            </IonSegmentButton>
            <IonSegmentButton value="product" style={{ fontSize: 12 }}>
              Products ({countsByType.products})
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

        {/* results list */}
        {!!visibleResults.length && (
          <IonList>
            {visibleSegment === 'custom' && (
              <IonItem
                onClick={() => setIsCreateItemModalOpen(true)}
                detail
                detailIcon={addCircleOutline}
                button
              >
                <IonIcon color="primary" icon={addCircleOutline} slot="start" />
                <IonLabel color="primary">Create your own item</IonLabel>
              </IonItem>
            )}
            {visibleResults.map((result) => (
              <IonItem
                key={result.id}
                onClick={onClickItem ? () => onClickItem(result) : undefined}
                button={!!onClickItem}
                detail={false}
              >
                <IonAvatar slot="start">
                  <img
                    src={spoonacularImageSrc(result.image, '100')}
                    alt={result.name}
                  />
                </IonAvatar>
                {onSelectItem && onDeselectItem && (
                  <IonCheckbox
                    slot="end"
                    checked={selectedItemIds.includes(result.id)}
                    onClick={(e) => {
                      // prevent the item from also handling a click
                      e.preventDefault();
                      e.stopPropagation();
                      if (!selectedItemIds.includes(result.id)) {
                        onSelectItem(result);
                      } else {
                        onDeselectItem(result);
                      }
                    }}
                  />
                )}

                <IonLabel style={{ textTransform: 'capitalize' }}>
                  {result.name}
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}

        {/* Create Item CTA */}
        <IonGrid class="ion-text-center">
          <IonRow>
            <IonCol>
              <IonLabel>Not seeing what you're looking for?</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                color="secondary"
                fill="outline"
                onClick={() => setIsCreateItemModalOpen(true)}
              >
                <IonIcon icon={createOutline} /> Create your own item
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </section>

      <CreateItemModal
        isOpen={isCreateItemModalOpen}
        onDismiss={() => setIsCreateItemModalOpen(false)}
      />
    </>
  );
};

interface ICreateItemModal {
  isOpen: boolean;
  onDismiss: () => void;
}

const CreateItemModal: React.FC<ICreateItemModal> = ({ isOpen, onDismiss }) => {
  const currentUser = useCurrentUser();
  const [name, setName] = useState<string>('');
  const [aisle, setAisle] = useState<string>('');
  const [type, setType] = useState<'product' | 'ingredient'>();

  const handleCreate = () => {
    currentUser?.ref
      .collection('customItems')
      .add({
        name,
        aisle,
        type: 'type',
        source: 'custom',
        image: '',
      })
      .then(() => onDismiss());
  };

  return (
    <ActionModal
      modalName="Add Custom Item"
      title="Create an item"
      isOpen={isOpen}
      buttons={[{ label: 'Save', onClick: handleCreate }, { label: 'Cancel' }]}
      onDismiss={onDismiss}
    >
      <form>
        <IonItem>
          <IonRadioGroup
            value={type}
            onIonChange={(e) => setType(e.detail.value)}
            style={{ width: '100%' }}
          >
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <IonItem lines="none">
                    <IonLabel>Product</IonLabel>
                    <IonRadio slot="start" value="product" />
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonItem lines="none">
                    <IonLabel>Ingredient</IonLabel>
                    <IonRadio slot="start" value="ingredient" />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonRadioGroup>
        </IonItem>

        <IonItem>
          <IonInput
            placeholder="Name"
            value={name}
            onIonChange={(e) => setName(e.detail.value || '')}
          />
        </IonItem>

        <IonItem>
          <IonSelect
            value={aisle}
            onIonChange={(e) => setAisle(e.detail.value)}
            placeholder="Aisle"
          >
            {AISLES.map((_aisle) => (
              <IonSelectOption key={_aisle} value={_aisle}>
                {_aisle}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>

        {/* <IonItem>
          <IonLabel position="stacked">Possible Units</IonLabel>
          <IonGrid>
            <IonRow>
              {UNITS.map((unit) => (
                <IonCol size="4">
                  <IonItem lines="none">
                    <IonCheckbox />
                    &nbsp;<IonLabel>{unit}</IonLabel>
                  </IonItem>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </IonItem> */}
      </form>
    </ActionModal>
  );
};

const autoCompleteReducer = (
  state: {
    autoCompleteSearchQuery: string;
    productSearchQuery: string;
    shouldShowAutoCompleteList: boolean;
  },
  action:
    | { type: 'setAutoCompleteSearchQuery'; value: string }
    | { type: 'setProductSearchQuery'; value: string }
    | { type: 'hideAutoCompleteList' }
    | { type: 'showAutoCompleteList' },
) => {
  switch (action.type) {
    case 'setProductSearchQuery': {
      return {
        productSearchQuery: action.value,
        autoCompleteSearchQuery: '',
        shouldShowAutoCompleteList: false,
      };
    }
    case 'setAutoCompleteSearchQuery': {
      return {
        autoCompleteSearchQuery: action.value,
        productSearchQuery: '',
        shouldShowAutoCompleteList: !!action.value,
      };
    }

    case 'showAutoCompleteList': {
      return {
        ...state,
        shouldShowAutoCompleteList: true,
      };
    }

    case 'hideAutoCompleteList': {
      return {
        ...state,
        shouldShowAutoCompleteList: false,
      };
    }
  }
};
export default ProductSearch;
