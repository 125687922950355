import React, { useState, useMemo } from 'react';
import * as CopyToClipboard from 'react-copy-to-clipboard';

import { IGroup } from '../../types/IGroup';
import Layout from '../layouts/Layout';
import {
  IonLabel,
  IonList,
  IonItem,
  IonFab,
  IonFabButton,
  IonIcon,
  IonFabList,
  IonAlert,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonCard,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
} from '@ionic/react';

import {
  ellipsisVerticalOutline,
  exitOutline,
  copyOutline,
  pencilOutline,
} from 'ionicons/icons';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import UserAvatar from '../components/UserAvatar';
import useGroupMembership from '../hooks/useGroupMembership';
import useGlobalToast from '../hooks/useGlobalToast';
import { IUser } from '../../types/IUser';
import FabButton from '../components/FabButton';
import { AddEditGroupModal } from './GroupsTab';

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
  history: {
    push: (uri: string) => void;
  };
}
const ViewGroup: React.FC<IProps> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const currentUser = useRequireCurrentUser();
  const [isLeaveAlertOpen, setIsLeaveAlertOpen] = useState<boolean>(false);
  const { groups, userIdsByGroupId, users } = useGroupMembership();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const group = useMemo(() => groups.find((g) => g.id === id), [groups, id]);
  const members = useMemo(() => {
    if (userIdsByGroupId && group) {
      const userIds = userIdsByGroupId[group.id] || [];
      return users.filter((user) => userIds.includes(user.id));
    }

    return [];
  }, [group, userIdsByGroupId, users]);

  const isAdmin = useMemo(() => group?.admins?.includes(currentUser.id), [
    group,
    currentUser,
  ]);

  if (!group) {
    return null;
  }

  const confirmLeave = () => setIsLeaveAlertOpen(true);
  const handleLeave = async () => {
    await currentUser.ref.collection('memberOfGroups').doc(id).delete();

    history.push('/groups');
  };

  const { name, description } = group;

  return (
    <Layout canGoBack="/groups" className="view-group" pageName="View Group">
      <IonToolbar>
        <IonTitle>{name}</IonTitle>
      </IonToolbar>

      {description && (
        <IonCard>
          <IonCardContent>{description}</IonCardContent>
        </IonCard>
      )}
      <InviteCard group={group} />

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Members ({members.length})</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList>
            {members.map((user) => (
              <UserItem key={user.id} user={user} />
            ))}
          </IonList>
        </IonCardContent>
      </IonCard>

      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton>
          <IonIcon icon={ellipsisVerticalOutline} />
        </IonFabButton>
        <IonFabList side="top" className="fab-list-with-labels">
          <FabButton
            label="Leave group"
            size="small"
            color="danger"
            onClick={() => confirmLeave()}
          >
            <IonIcon icon={exitOutline} />
          </FabButton>
          {isAdmin && (
            <FabButton
              color="secondary"
              label="Edit"
              size="small"
              onClick={() => setIsEditModalOpen(true)}
            >
              <IonIcon icon={pencilOutline} />
            </FabButton>
          )}
          {/* <FabButton color="primary" label="">
            <IonIcon icon={mailOutline} />
          </FabButton> */}
        </IonFabList>
      </IonFab>

      <IonAlert
        isOpen={isLeaveAlertOpen}
        header="Are you sure?"
        message={`Are you sure you want to leave ${group.name}?`}
        onDidDismiss={() => setIsLeaveAlertOpen(false)}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },

          {
            text: 'Leave',
            cssClass: 'primary',
            handler: handleLeave,
          },
        ]}
      ></IonAlert>

      <AddEditGroupModal
        key={`edit-group-${group.id}`}
        isOpen={isEditModalOpen}
        group={group}
        onDismiss={() => setIsEditModalOpen(false)}
      />
    </Layout>
  );
};

const UserItem = ({ user }: { user: IUser }) => {
  return (
    <IonItem key={user.id}>
      <UserAvatar user={user} slot="start" /> <IonLabel>{user.name}</IonLabel>
    </IonItem>
  );
};

const InviteCard = ({ group }: { group: IGroup }) => {
  const { setGlobalToast } = useGlobalToast();

  const inviteUrl = useMemo(() => {
    if (group) {
      return `${window.location.origin}/invite/${group.id}`;
    }
  }, [group]);

  if (!inviteUrl) {
    return null;
  }
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Invite people to this group</IonCardTitle>
        <IonCardSubtitle>Share this link</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        {/* <IonInput value={group.id} readonly /> */}

        <IonItem>
          <span style={{ fontSize: 14 }}>{inviteUrl}</span>
          <IonButtons slot="end">
            <CopyToClipboard
              text={inviteUrl}
              onCopy={() => setGlobalToast('Link has been copied to clipboard')}
            >
              <IonButton slot="end" fill="clear">
                <IonIcon icon={copyOutline} />
              </IonButton>
            </CopyToClipboard>
          </IonButtons>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default ViewGroup;
