import React, { useState, useMemo } from 'react';
import { IShoppingList } from '../../types/IShoppingList';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import firebase from '../firebase';
import uniq from 'lodash/uniq';
import ActionModal from './ActionModal';
import {
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonDatetime,
  IonChip,
  IonIcon,
  IonText,
  IonCheckbox,
  IonButtons,
  IonButton,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { peopleOutline, eyeOutline, eyeOffOutline } from 'ionicons/icons';
import useGroupMembership from '../hooks/useGroupMembership';
import { addShoppingList } from '../helpers/dataMutations';

export const AddEditShoppingListModal = ({
  isOpen,
  onDismiss,
  shoppingList,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  shoppingList?: IShoppingList;
}) => {
  const currentUser = useRequireCurrentUser();
  const [name, setName] = useState<string>(shoppingList?.name || '');
  const [targetDate, setTargetDate] = useState<string>(
    shoppingList?.targetDate?.toDate().toDateString() || '',
  );

  const { groups } = useGroupMembership();
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>(
    shoppingList?.visibleToGroupIds || [],
  );

  const handleGroupToggle = (groupId: string, checked: boolean) => {
    if (checked) {
      setSelectedGroupIds((g) => g.concat(groupId));
    } else {
      setSelectedGroupIds((g) => g.filter((_id) => _id !== groupId));
    }
  };

  const handleSave = async () => {
    const data = {
      name,
      targetDate: targetDate
        ? firebase.firestore.Timestamp.fromMillis(Date.parse(targetDate))
        : null,
      visibleToGroupIds: uniq(selectedGroupIds),
    };

    if (shoppingList) {
      await shoppingList.ref.update(data);
    } else {
      await addShoppingList(data, currentUser);
    }
    onDismiss();
  };

  const pageName = useMemo(
    () => `${shoppingList ? 'Update' : 'Add'} Shopping List`,
    [shoppingList],
  );

  return (
    <ActionModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      modalName={pageName}
      title={pageName}
      buttons={[
        { label: shoppingList ? 'Update' : 'Save', onClick: handleSave },
        { label: 'Cancel', onClick: onDismiss },
      ]}
    >
      <IonContent fullscreen class="ion-padding">
        <IonItem>
          <IonLabel position="floating">Name</IonLabel>
          <IonInput
            value={name}
            onIonChange={(e) => setName(e.detail.value as string)}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="floating">Target Shopping Date</IonLabel>
          <IonDatetime
            value={targetDate}
            onIonChange={(e) => setTargetDate(e.detail.value as string)}
          />
        </IonItem>

        <IonItem>
          <IonGrid style={{ paddingLeft: 0 }}>
            <IonRow>
              <IonText>Visibility</IonText>
            </IonRow>
            <IonRow>
              <IonButtons>
                <IonButton
                  fill="clear"
                  onClick={() => setSelectedGroupIds(groups.map((g) => g.id))}
                  color="primary"
                >
                  <IonIcon icon={eyeOutline} />
                  &nbsp;Everyone
                </IonButton>
                <IonButton
                  fill="clear"
                  onClick={() => setSelectedGroupIds([])}
                  color="secondary"
                >
                  <IonIcon icon={eyeOffOutline} />
                  &nbsp; No one
                </IonButton>
              </IonButtons>

              {groups.map((group) => (
                <IonChip key={group.id} outline>
                  <IonIcon icon={peopleOutline} />
                  <IonLabel>{group.name}</IonLabel>
                  &nbsp;
                  <IonCheckbox
                    checked={selectedGroupIds.includes(group.id)}
                    onIonChange={(e) =>
                      handleGroupToggle(group.id, e.detail.checked)
                    }
                  />
                </IonChip>
              ))}
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonContent>
    </ActionModal>
  );
};

export default AddEditShoppingListModal;
