import React, { useEffect, useCallback, useMemo } from 'react';
import Layout from '../layouts/Layout';
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCard,
  IonCardContent,
  IonButton,
  IonIcon,
  IonNote,
} from '@ionic/react';
import useGroup from '../hooks/useGroup';
import { peopleOutline } from 'ionicons/icons';
import { addUserToGroup } from '../helpers/dataMutations';
import { useCurrentUser } from '../hooks';
import { useHistory, RouteComponentProps } from 'react-router';

interface IProps extends RouteComponentProps<{ code: string }> {}

const InvitePage: React.FC<IProps> = (props) => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const {
    match: { params: { code = '' } = {} },
    location: { search },
  } = props;

  const autojoin = search.includes('autojoin=true');
  const { group, loading } = useGroup(code);

  const handleJoin = useCallback(async () => {
    if (currentUser && group) {
      await addUserToGroup(currentUser, group);
      // @ts-ignore - very weird bug here where history.replace was causing rendering issues.
      window.location = `/groups/${group.id}`;
    } else {
      const currentUri = window.location.toString();
      window.localStorage.setItem(
        'redirectAfterSignIn',
        [currentUri, 'autojoin=true'].join(
          currentUri.includes('?') ? '&' : '?',
        ),
      );
      history.push('/sign-in');
    }
  }, [currentUser, group, history]);

  useEffect(() => {
    if (group && autojoin) {
      handleJoin();
    }
  }, [group, handleJoin, autojoin]);

  if (!group && loading === false) {
    return null;
    // throw new Error("Couldn't find a group.");
  }

  if (loading || !group || autojoin) {
    return null;
  }

  return (
    <Layout pageName="Invite" hideAppMenu={!currentUser}>
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>You've been invited to join</IonCardSubtitle>
          <IonCardTitle>{group.name}</IonCardTitle>
          <IonCardSubtitle>{group.description}</IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
          <IonButton expand="block" onClick={handleJoin}>
            <IonIcon icon={peopleOutline} />
            &nbsp;Join this group now
          </IonButton>
          {!currentUser && (
            <IonNote>
              You'll be taken to sign in or sign up for an account to get
              started.
            </IonNote>
          )}
        </IonCardContent>
      </IonCard>
    </Layout>
  );
};

export default InvitePage;
