import React, { useMemo, useState, useCallback } from 'react';
import {
  IShoppingList,
  IShoppingListItem,
  IShoppingListData,
} from '../../types/IShoppingList';
import useShoppingListItems from '../hooks/useShoppingListItems';
import { firestore } from '../firebase';
import ActionModal from './ActionModal';
import {
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonAvatar,
  IonCheckbox,
  IonAlert,
  IonText,
} from '@ionic/react';
import {
  updateShoppingLists,
  duplicateShoppingList,
} from '../helpers/dataMutations';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';
import useGlobalToast from '../hooks/useGlobalToast';

interface IConfirmCompleteShoppingListModalProps {
  shoppingLists: IShoppingList[];
  isOpen: boolean;
  onDismiss: (result?: { status: 'archived' }) => void;
  additionalData?: Partial<IShoppingListData>;
}

const ConfirmCompleteShoppingListModal: React.FC<IConfirmCompleteShoppingListModalProps> = ({
  shoppingLists,
  isOpen,
  onDismiss,
  additionalData = {},
}) => {
  const currentUser = useRequireCurrentUser();
  const { setGlobalToast } = useGlobalToast();

  const shoppingListItemsRef = useMemo(
    () =>
      firestore.collectionGroup('shoppingListItems').where(
        'shoppingListId',
        'in',
        shoppingLists.map((l) => l.id),
      ),
    [shoppingLists],
  );
  const { items, loading } = useShoppingListItems(shoppingListItemsRef);
  const undibbedItems = useMemo(
    () => items?.filter((item) => !item.dibbedBy && !item.clonedFromItemId),
    [items],
  );

  const [selectedItems, setSelectedItems] = useState<{
    [itemId: string]: IShoppingListItem;
  }>({});

  const [newListName, setNewListName] = useState<string>(
    `Carryover from ${shoppingLists[0].name}`,
  );

  const toggleSelectedItem = useCallback((item, selected) => {
    const val = selected ? item : null;
    setSelectedItems((_items) => ({
      ..._items,
      [item.id]: val,
    }));
  }, []);

  const selectedItemCount = useMemo(
    () => Object.values(selectedItems).filter(Boolean).length,
    [selectedItems],
  );

  const displayStatusToast = useCallback(
    (status: 'archived' | 'active', carryover: boolean) => {
      if (status === 'archived') {
        if (carryover) {
          setGlobalToast(
            'Your items have been carried over to your new list and this list has been archived. It will not be visible to others in your groups.',
          );
        } else {
          setGlobalToast(
            'Your list has been archived and can be found under the Archived tab. It will not be visible to others in your groups.',
          );
        }
      }
    },
    [setGlobalToast],
  );

  const handleDone = async () => {
    if (selectedItemCount === 0) {
      await updateShoppingLists(shoppingLists, {
        status: 'archived',
        ...additionalData,
      });
      displayStatusToast('archived', false);
      onDismiss();
    } else {
      await duplicateShoppingList(
        shoppingLists[0],
        {
          items: Object.values(selectedItems),
          name: newListName,
          visibleToGroupIds: shoppingLists[0].visibleToGroupIds,
        },
        currentUser,
      );

      await updateShoppingLists(shoppingLists, {
        status: 'archived',
        ...additionalData,
      });
      displayStatusToast('archived', true);
      onDismiss();
    }
  };

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (undibbedItems) {
        for (const item of undibbedItems) {
          toggleSelectedItem(item, checked);
        }
      }
    },
    [undibbedItems, toggleSelectedItem],
  );

  if (loading || !undibbedItems) {
    return null;
  }

  if (undibbedItems.length === 0) {
    return (
      <IonAlert
        isOpen={isOpen}
        header="All finished?"
        message="Your list will be archived and can be found under the Plan tab."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: onDismiss,
          },
          { text: 'Finished', role: 'primary', handler: handleDone },
        ]}
      />
    );
  }

  return (
    <ActionModal
      subtitle="Carryover"
      title="Incomplete Items"
      modalName="Shopping List Archive Followup"
      isOpen={isOpen}
      onDismiss={onDismiss}
      buttons={[
        {
          label: selectedItemCount
            ? `Move (${selectedItemCount})`
            : 'No, thanks',
          onClick: handleDone,
        },
        {
          label: 'Cancel',
          onClick: () => onDismiss(),
        },
      ]}
    >
      <>
        <p>
          Before we finalize this list, select any of the unfulfilled items
          below to move them to a new list. Or not, up to you.
        </p>

        {selectedItemCount > 0 && (
          <IonItem lines="none">
            <IonLabel position="floating">Name your new list</IonLabel>
            <IonInput
              placeholder="Name your new list"
              value={newListName}
              onIonChange={(e) => setNewListName(e.detail.value || '')}
            />
          </IonItem>
        )}

        <IonList>
          <IonItem>
            <IonText slot="end" color="medium" style={{ fontSize: 12 }}>
              Select all
            </IonText>
            <IonCheckbox
              slot="end"
              style={{ marginLeft: 5 }}
              onIonChange={(e) => handleSelectAll(e.detail.checked)}
            />
          </IonItem>
          {undibbedItems.map((item) => (
            <IonItem key={item.id}>
              <IonAvatar slot="start">
                <img src={item.image} alt={item.name} />
              </IonAvatar>
              <IonLabel>{item.name}</IonLabel>
              <IonCheckbox
                checked={!!selectedItems[item.id]}
                onIonChange={(e) => toggleSelectedItem(item, e.detail.checked)}
              />
            </IonItem>
          ))}
        </IonList>
      </>
    </ActionModal>
  );
};

export default ConfirmCompleteShoppingListModal;
