import { useState, useRef, useCallback } from 'react';

const useTimedLoading = (timeoutMs: number) => {
  const [loading, setLoading] = useState<boolean>();
  const [timedOut, setTimedOut] = useState<boolean>();
  const timeout = useRef<number>();

  const start = useCallback(() => {
    setLoading(true);
    timeout.current = window.setTimeout(() => {
      setLoading(false);
      setTimedOut(true);
    }, timeoutMs);
  }, [timeoutMs]);

  const clear = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setLoading(false);
  }, []);

  return {
    loading,
    start,
    clear,
    timedOut,
  };
};

export default useTimedLoading;
