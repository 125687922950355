import { useEffect, useState, createContext, useContext } from 'react';
import firebase from '../firebase';
import useCurrentUser from './useCurrentUser';
import {
  userFromDoc,
  groupFromDoc,
  shoppingListFromDoc,
} from '../helpers/dataMappers';
import { IUser } from '../../types/IUser';
import { IGroup } from '../../types/IGroup';
import { IShoppingList } from '../../types/IShoppingList';
import useTimedLoading from './useTimedLoading';
import { safeFirestoreIn } from '../helpers/firebase';

type tUserIdsByGroupId = { [groupId: string]: string[] };
// @ts-ignore
const GroupMembershipContext: React.Context<{
  users: IUser[];
  groups: IGroup[];
  shoppingLists: IShoppingList[];
  userIdsByGroupId: tUserIdsByGroupId;
  loading: boolean | null;
}> = createContext({
  users: [],
  groups: [],
  shoppingLists: [],
  loading: null,
});

const useInitializeGroupMembership = () => {
  const currentUser = useCurrentUser();
  const [users, setUsers] = useState<IUser[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [shoppingLists, setShoppingLists] = useState<IShoppingList[]>([]);
  const [userIdsByGroupId, setUserIdsByGroupId] = useState<{
    [groupId: string]: string[];
  }>({});
  const {
    loading = null,
    start: startLoading,
    clear: clearLoading,
  } = useTimedLoading(1500);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    startLoading();

    const unsubs: Array<() => void> = [];
    const q = (cb: () => void) => unsubs.push(cb);

    q(
      currentUser.ref.collection('memberOfGroups').onSnapshot((docs) => {
        const groupIds = docs.docs.map((doc) => doc.data().groupId);
        if (groupIds.length) {
          q(
            firebase
              .firestore()
              .collection('groups')
              .where(firebase.firestore.FieldPath.documentId(), 'in', groupIds)
              .onSnapshot((groupDocs) => {
                const _groups = groupDocs.docs.map((doc) => groupFromDoc(doc));
                setGroups(_groups);
                clearLoading();
              }),
          );
        }
      }),
    );

    return () => unsubs.forEach((unsub) => unsub());
  }, [currentUser, clearLoading, startLoading]);

  useEffect(() => {
    const groupIds = groups.map((g) => g.id);

    if (groupIds.length) {
      return safeFirestoreIn(
        firebase.firestore().collectionGroup('memberOfGroups'),
        'groupId',
        groupIds,
      ).onSnapshot((snap) => {
        const _userIdsByGroupId: tUserIdsByGroupId = {};
        const _userIds: string[] = [];
        for (const doc of snap.docs) {
          const data = doc.data();
          const userId = data.userId as string;
          const groupId = data.groupId as string;
          _userIds.push(userId);
          if (!_userIdsByGroupId[groupId]) {
            _userIdsByGroupId[groupId] = [];
          }
          _userIdsByGroupId[groupId].push(userId);
        }

        setUserIds(_userIds);
        setUserIdsByGroupId(_userIdsByGroupId);
      });
    }
  }, [groups]);

  useEffect(() => {
    const _userIds = userIds.concat(currentUser?.id || '').filter(Boolean);
    if (_userIds.length) {
      return safeFirestoreIn(
        firebase.firestore().collection('users'),
        firebase.firestore.FieldPath.documentId(),
        _userIds,
      ).onSnapshot((docs) => {
        const _users = docs.docs.map(userFromDoc);
        setUsers(_users);
      });
    }
  }, [userIds, currentUser]);

  useEffect(() => {
    const groupIds = groups.map((g) => g.id);

    if (groupIds.length) {
      return firebase
        .firestore()
        .collectionGroup('shoppingLists')
        .where('status', '==', 'active')
        .where('visibleToGroupIds', 'array-contains-any', groupIds)
        .onSnapshot((snap) => {
          const _shoppingLists = snap.docs.map(shoppingListFromDoc);
          setShoppingLists(_shoppingLists);
        });
    }
  }, [groups]);

  return {
    users,
    groups,
    shoppingLists,
    userIdsByGroupId,
    loading,
  };
};

const useGroupMembership = () => {
  const {
    users,
    groups,
    shoppingLists,
    loading,
    userIdsByGroupId,
  } = useContext(GroupMembershipContext);

  return {
    users,
    groups,
    shoppingLists,
    loading,
    userIdsByGroupId,
  };
};
export default useGroupMembership;
export { GroupMembershipContext, useInitializeGroupMembership };
