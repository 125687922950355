import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import AvatarEditor from 'react-avatar-editor';

import {
  IonRange,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonButtons,
  IonToolbar,
  IonInput,
} from '@ionic/react';
import {
  refreshOutline,
  addCircleOutline,
  removeCircleOutline,
  imageOutline,
} from 'ionicons/icons';

interface IProps {
  image?: string | File;
  hideTools?: boolean;
  onAvatarUpdated?: (file: string | File | undefined) => void;
}
interface IRef {
  getImageAsBlob: () => Promise<Blob>;
}

const AvatarSelector: React.FC<IProps> = (
  { image: initialImage, hideTools, onAvatarUpdated },
  ref,
) => {
  const [image, setImage] = useState<string | File | undefined>(initialImage);
  const [rotation, setRotation] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);
  const avatarEditor = useRef<AvatarEditor | null>();
  const [dirty, setDirty] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLIonInputElement>();

  const handleFileAdd = async (e: CustomEvent) => {
    // @ts-ignore
    const input = await fileInputRef.current?.getInputElement();

    console.log('input: ', input, input?.files);
    if (input?.files?.length) {
      const file = input.files[0];
      setImage(file);
      setDirty(true);
      if (onAvatarUpdated) {
        onAvatarUpdated(file);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    getImageAsBlob() {
      const canvas = avatarEditor.current?.getImageScaledToCanvas();
      return new Promise((resolve) => canvas?.toBlob(resolve));
    },
    isDirty() {
      return dirty || rotation !== 0 || scale !== 1;
    },
  }));

  const fileSelector = (
    <label style={{ cursor: 'pointer' }}>
      <IonIcon icon={imageOutline} />
      &nbsp; Upload a new photo
      <IonInput
        name="file-input"
        // @ts-ignore
        type="file"
        accept="image/png,image/jpeg"
        onIonChange={handleFileAdd}
        ref={(_ref) => {
          if (_ref) {
            fileInputRef.current = _ref;
          }
        }}
        style={{ display: 'none' }}
      />
    </label>
  );
  return (
    <IonGrid className="avatar-selector">
      <IonRow>
        <IonCol className="ion-text-center" size="12">
          {image && (
            <div className="avatar-editor">
              <AvatarEditor
                image={image}
                borderRadius={100}
                color={[255, 255, 255, 0.5]}
                rotate={rotation}
                scale={scale}
                ref={(_ref) => (avatarEditor.current = _ref)}
                crossOrigin="anonymous"
                style={{ width: 300, height: 300 }}
              />
            </div>
          )}

          <IonToolbar
            className="ion-text-center"
            style={{ display: 'inline-block' }}
          >
            <IonButtons
              className="ion-text-center"
              style={{ display: 'block' }}
            >
              <IonButton fill="clear">
                {/* {newImageMode ? 'Nevermind' : 'Choose a new image'} */}
                {fileSelector}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCol>
      </IonRow>

      {!hideTools && (
        <IonRow>
          <IonCol size="12" sizeMd="6">
            {/* rotate */}
            <IonRange
              min={-180}
              max={180}
              value={rotation}
              onIonChange={(e) => setRotation(e.detail.value as number)}
            >
              <IonIcon
                icon={refreshOutline}
                slot="start"
                style={{ transform: 'scaleX(-1)' }}
              />
              <IonIcon icon={refreshOutline} slot="end" />
            </IonRange>
          </IonCol>
          <IonCol size="12" sizeMd="6">
            {/* zoom */}
            <IonRange
              min={0.5}
              max={10}
              value={scale}
              step={0.1}
              onIonChange={(e) => setScale(e.detail.value as number)}
            >
              <IonIcon icon={removeCircleOutline} slot="start" />
              <IonIcon icon={addCircleOutline} slot="end" />
            </IonRange>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};

// @ts-ignore
export default forwardRef<IRef, IProps>(AvatarSelector);
