import React, { useState, useMemo } from 'react';
import {
  IonLabel,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButton,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonCardHeader,
  IonCardTitle,
  IonCard,
  IonCardSubtitle,
  IonCardContent,
  IonSkeletonText,
  IonBadge,
  IonAlert,
} from '@ionic/react';
import {
  addOutline,
  addCircleOutline,
  eyeOffOutline,
  eyeOutline,
} from 'ionicons/icons';
import Layout from '../layouts/Layout';
import AddEditShoppingListModal from '../components/AddEditShoppingListModal';
import './ShoppingListsTab.css';

import { IShoppingList } from '../../types/IShoppingList';
import useGroupMembership from '../hooks/useGroupMembership';
import { IGroup } from '../../types/IGroup';
import useShoppingLists from '../hooks/useShoppingLists';
import { useRequireCurrentUser } from '../hooks/useCurrentUser';

const ListItem = ({ shoppingList }: { shoppingList: IShoppingList }) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const {
    createdAt,
    name,
    id,
    status,
    shoppingStatus = 'planning',
    visibleToGroupIds = [],
  } = shoppingList;

  const listIsPrivate = useMemo(() => !visibleToGroupIds.length, [
    visibleToGroupIds,
  ]);

  return (
    <>
      <IonCard routerLink={`/groceries/shopping-lists/${id}`}>
        <IonButton
          fill="clear"
          className="ion-float-right"
          color={visibleToGroupIds.length === 0 ? 'danger' : ''}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAlertOpen(true);
          }}
          style={{
            position: 'absolute',
            right: 0,
            top: -5,
            zIndex: 1000,
          }}
        >
          {listIsPrivate && (
            <>
              <IonIcon icon={eyeOffOutline} />
              &nbsp; Private
            </>
          )}

          {!listIsPrivate && (
            <>
              <IonIcon icon={eyeOutline} />
              &nbsp;{visibleToGroupIds.length} groups
            </>
          )}
        </IonButton>

        <IonCardHeader>
          <IonCardSubtitle>
            {createdAt.toDate().toLocaleDateString('en-US')}
          </IonCardSubtitle>
          <IonCardTitle style={{ fontSize: 22 }}>{name}</IonCardTitle>
          <IonCardSubtitle className="ion-text-capitalize">
            <IonBadge>
              {status === 'archived' ? 'archived' : shoppingStatus}
            </IonBadge>
          </IonCardSubtitle>
        </IonCardHeader>
      </IonCard>
      <ShoppingListVisibilityAlert
        isOpen={alertOpen}
        shoppingList={shoppingList}
        onDidDismiss={() => setAlertOpen(false)}
      />
    </>
  );
};

interface IShoppingListVisibilityAlertProps {
  isOpen: boolean;
  shoppingList: IShoppingList;
  onDidDismiss: () => void;
}
const ShoppingListVisibilityAlert: React.FC<IShoppingListVisibilityAlertProps> = ({
  isOpen,
  shoppingList,
  onDidDismiss,
}) => {
  const { visibleToGroupIds = [] } = shoppingList;
  const { groups } = useGroupMembership();

  const [selectedVisibleToGroupIds, setSelectedVisibleToGroupIds] = useState<
    string[]
  >(visibleToGroupIds);
  const [shouldSave, setShouldSave] = useState<boolean>(false);

  const handleVisibleToGroupIdChecked = (groupId: string, checked: boolean) => {
    if (checked) {
      setSelectedVisibleToGroupIds(selectedVisibleToGroupIds.concat(groupId));
    } else {
      setSelectedVisibleToGroupIds(
        selectedVisibleToGroupIds.filter((_id) => groupId !== _id),
      );
    }
  };

  const handleSave = () => {
    setShouldSave(true);
  };

  const handleDidDismiss = () => {
    if (shouldSave) {
      shoppingList.ref.update({
        visibleToGroupIds: selectedVisibleToGroupIds,
      });
      setShouldSave(false);
    } else {
      setSelectedVisibleToGroupIds(visibleToGroupIds);
      setShouldSave(false);
    }
    onDidDismiss();
  };

  return (
    <IonAlert
      isOpen={isOpen}
      backdropDismiss
      header="Visible to members of..."
      // @ts-ignore
      inputs={groups.map((group: IGroup) => ({
        name: `checkbox-${group?.id}`,
        type: 'checkbox',
        label: group?.name,
        value: group?.id,
        checked: selectedVisibleToGroupIds.includes(group.id),
        handler: (input) => {
          handleVisibleToGroupIdChecked(group.id, !!input.checked);
        },
      }))}
      buttons={[
        { text: 'Close', role: 'cancel', handler: () => onDidDismiss() },
        {
          text: 'Save',
          handler: handleSave,
        },
      ]}
      onDidDismiss={handleDidDismiss}
    />
  );
};

const SkeletonCard = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>
          <IonSkeletonText animated style={{ width: '50%' }} />
        </IonCardSubtitle>
        <IonCardTitle>
          <IonSkeletonText animated />
        </IonCardTitle>
      </IonCardHeader>
    </IonCard>
  );
};
const ShoppingListTab: React.FC = () => {
  const currentUser = useRequireCurrentUser();
  const [activeOrArchived, setActiveOrArchived] = useState<string>('active');
  const [showAddModal, setShowAddModal] = useState(false);
  const { lists, loading } = useShoppingLists(currentUser);

  const visibleLists = useMemo(
    () => lists.filter((l) => l.status === activeOrArchived),
    [lists, activeOrArchived],
  );
  return (
    <>
      <Layout className="shopping-lists" pageName="View Shopping Lists">
        <IonToolbar>
          <IonSegment
            value={activeOrArchived}
            onIonChange={(e) => {
              setActiveOrArchived(e.detail.value || 'active');
            }}
          >
            <IonSegmentButton value="active">
              <IonLabel>Active</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="archived">
              <IonLabel>Archived</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

        {loading && (
          <>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </>
        )}
        {visibleLists.map((l) => (
          <ListItem key={l.id} shoppingList={l} />
        ))}
        {!loading &&
          activeOrArchived === 'active' &&
          visibleLists.length === 0 && (
            <IonCard style={{ marginTop: 100 }}>
              <IonCardContent>
                <p>Create your a list and add some items to get started.</p>
                <IonButton
                  expand="block"
                  onClick={() => setShowAddModal(true)}
                  fill="outline"
                >
                  <IonIcon icon={addCircleOutline} />
                  &nbsp; Add a new list
                </IonButton>
              </IonCardContent>
            </IonCard>
          )}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => setShowAddModal(true)}>
            <IonIcon icon={addOutline} />
          </IonFabButton>
        </IonFab>
      </Layout>

      <AddEditShoppingListModal
        isOpen={showAddModal}
        onDismiss={() => setShowAddModal(false)}
      />
    </>
  );
};

export default ShoppingListTab;
