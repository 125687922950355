import React, { useState, SyntheticEvent } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonPopover,
  IonLabel,
  IonItem,
  IonIcon,
  IonMenuButton,
} from '@ionic/react';
import { useCurrentUser } from '../hooks';
import { logOutOutline, personOutline } from 'ionicons/icons';
import firebase from '../firebase';
import UserAvatar from './UserAvatar';
import { IUser } from '../../types/IUser';

interface IProps {
  title?: string;
  canGoBack?: string;
  hideAppMenu?: boolean;
}

const BackButton = ({ defaultHref = '/' }: { defaultHref?: string }) => (
  <IonButtons slot="start">
    <IonBackButton defaultHref={defaultHref} />
  </IonButtons>
);

const UserMenu = ({
  isOpen,
  onDidDismiss,
  event,
}: {
  isOpen: boolean;
  onDidDismiss: () => void;
  user: IUser;
  event: any;
}) => {
  const handleLogout = () => {
    onDidDismiss();
    firebase.auth().signOut();
  };
  return (
    <IonPopover isOpen={isOpen} onDidDismiss={onDidDismiss} event={event}>
      <IonItem detail={false} routerLink="/profile" onClick={onDidDismiss}>
        <IonIcon icon={personOutline} />
        <IonLabel>Profile</IonLabel>
      </IonItem>
      <IonItem href="#" onClick={handleLogout} detail={false}>
        <IonIcon icon={logOutOutline} />
        <IonLabel>Logout</IonLabel>
      </IonItem>
    </IonPopover>
  );
};

const Header: React.FC<IProps> = ({
  title = 'Backyard Collective',
  canGoBack,
  hideAppMenu,
}) => {
  const currentUser = useCurrentUser();
  const [userMenuEvent, setUserMenuEvent] = useState<SyntheticEvent | null>();

  const handleAvatarClick = (e: SyntheticEvent) => {
    if (!userMenuEvent) {
      e.persist();
      setUserMenuEvent(e);
    }
  };

  const handleClearUserMenu = () => {
    if (userMenuEvent) {
      userMenuEvent.stopPropagation();
    }
    setUserMenuEvent(null);
  };

  return (
    <>
      <IonHeader color="primary">
        <IonToolbar color="primary">
          <IonTitle>{title}</IonTitle>

          {canGoBack && (
            <BackButton
              defaultHref={
                typeof canGoBack === 'string' ? canGoBack : undefined
              }
            />
          )}
          {!canGoBack && !hideAppMenu && (
            <IonButtons slot="start">
              <IonMenuButton menu="main" />
            </IonButtons>
          )}

          {currentUser && (
            <>
              <IonButtons slot="end">
                <UserAvatar
                  user={currentUser}
                  slot="end"
                  size={36}
                  style={{
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                  onClick={handleAvatarClick}
                />
              </IonButtons>
              <UserMenu
                user={currentUser}
                isOpen={!!userMenuEvent}
                onDidDismiss={handleClearUserMenu}
                event={userMenuEvent}
              />
            </>
          )}
        </IonToolbar>
      </IonHeader>
    </>
  );
};

export default Header;
