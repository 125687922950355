import useFetch from 'use-http';
import urlJoin from 'url-join';
import qs from 'query-string';
import { useState, useEffect } from 'react';
import _flatten from 'lodash/flatten';

const API_KEY = '193613cfcb9947cfacdb9179addb9842';
const HOST = 'https://api.spoonacular.com';

interface IProductAutocompleteResponse {
  results: Array<{
    id: string;
    title: string;
  }>;
}

interface IProductSearchResponse {
  type: 'product';
  products: Array<{
    id: string;
    title: string;
    image: string;
    imageType: 'jpg' | 'png';
  }>;
  offset: number;
  number: number;
  totalProducts: number;
  processingTimeMs: number;
  expires: number;
}

type IIngredientAutocompleteResponse = Array<{
  id: string;
  name: string;
  image: string;
}>;

export interface IUnifiedAutocompleteItem {
  id: string;
  name: string;
  image: string;
  type: 'ingredient' | 'product';
  source: 'spoonacular' | 'custom';
}

export type IUnifiedAutocompleteResponse = IUnifiedAutocompleteItem[];

export interface IUnifiedFoodItem {
  name: string;
  aisle: string;
  type: 'ingredient' | 'product';
  id: string;
  source: 'spoonacular' | 'custom';
  image: string;
}

const useFetchIngredient = (id: string) =>
  useFetch(
    urlJoin(
      HOST,
      '/food/ingredients/',
      id,
      '/information',
      `?apiKey=${API_KEY}`,
    ),
    [id],
  );

const fetchIngredient = (id: string): Promise<IUnifiedFoodItem> =>
  fetch(
    urlJoin(
      HOST,
      '/food/ingredients/',
      id,
      '/information',
      `?apiKey=${API_KEY}`,
    ),
  )
    .then((resp) => resp.json())
    .then((data) => ({
      id,
      name: data.name,
      aisle: data.aisle,
      image: data.image,
      source: 'spoonacular',
      type: 'ingredient',
    }));

const fetchProduct = (id: string): Promise<IUnifiedFoodItem> =>
  fetch(urlJoin(HOST, '/food/products/', id, `?apiKey=${API_KEY}`))
    .then((resp) => resp.json())
    .then((data) => ({
      id,
      name: data.title,
      aisle: data.aisle,
      image: data.images[0],
      source: 'spoonacular',
      type: 'product',
    }));

const useAutocompleteSearch = (
  query: string,
  options: {
    type?: 'ingredient' | 'product' | 'all';
    detailed?: boolean;
    minChars?: number;
  } = {},
) => {
  const { type = 'all', minChars = 3, detailed = false } = options;

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IUnifiedAutocompleteItem[]>();

  useEffect(() => {
    const cleanQuery = _cleanQuery(query);
    if (!cleanQuery) {
      return;
    }

    const queryString = qs.stringify({
      number: 25,
      metaInformation: true,
      apiKey: API_KEY,
      query: cleanQuery,
    });

    const fetchSearchProducts = (): Promise<IUnifiedAutocompleteItem[]> => {
      const uri = urlJoin(HOST, '/food/products/search', `?${queryString}`);

      return fetch(uri)
        .then((resp) => resp.json())
        .then((json: IProductSearchResponse) =>
          json.products.map((product) => ({
            id: product.id.toString(),
            name: product.title,
            image: product.image,
            type: 'product',
            source: 'spoonacular',
          })),
        );
    };
    const fetchAutocompleteIngredients = (): Promise<
      IUnifiedAutocompleteResponse
    > => {
      const uri = urlJoin(
        HOST,
        '/food/ingredients/autocomplete',
        `?${queryString}`,
      );

      return fetch(uri)
        .then((resp) => resp.json())
        .then((json: IIngredientAutocompleteResponse) =>
          json.map((r) => ({
            ...r,
            id: r.id.toString(),
            type: 'ingredient',
            source: 'spoonacular',
          })),
        );
    };

    const fetchSuggestProducts = (): Promise<IUnifiedAutocompleteResponse> => {
      const uri = urlJoin(HOST, '/food/products/suggest', `?${queryString}`);

      return fetch(uri)
        .then((resp) => resp.json())
        .then((json: IProductAutocompleteResponse) =>
          json.results.map((r) => ({
            id: r.id.toString(),
            name: r.title,
            image: `https://spoonacular.com/productImages/${r.id}-312x231.jpg`,
            type: 'product',
            source: 'spoonacular',
          })),
        );
    };

    let fetchProducts: () => Promise<IUnifiedAutocompleteItem[]>;
    if (detailed) {
      // this is an optimization that allows the autocomplete to "pre-complete" the search if there's only matching item in autocomplete
      if (query.startsWith('__id=')) {
        const id = query.split('__id=')[1];
        fetchProducts = () =>
          fetchProduct(id).then((product) => [
            {
              id,
              name: product.name,
              image: product.image,
              type: 'product',
              source: 'spoonacular',
            },
          ]);
      } else {
        fetchProducts = fetchSearchProducts;
      }
    } else {
      fetchProducts = fetchSuggestProducts;
    }

    let doSearch: () => Promise<IUnifiedAutocompleteResponse> = () =>
      Promise.resolve([]);
    if (query.length >= minChars) {
      switch (type) {
        case 'ingredient':
          doSearch = fetchAutocompleteIngredients;
          break;

        case 'product':
          doSearch = fetchProducts;
          break;

        case 'all':
        default:
          doSearch = () =>
            Promise.all([
              fetchProducts(),
              fetchAutocompleteIngredients(),
            ]).then((results) => _flatten(results));
      }

      if (doSearch) {
        setLoading(true);
        doSearch().then((_data: any) => {
          setLoading(false);
          setData(_data);
        });
      }
    }
  }, [query, minChars, type, detailed]);

  return { loading, data };
};

/**
 * Clean the query before sending it in. There appears to be a problem with apostrophes and the spoonacular api.
 * e.g.https://api.spoonacular.com/food/products/search?query=nature's promise farm raised barramundi&number=10&apiKey=
 * @param query
 */
const STOPWORDS = ['nature'];
const _cleanQuery = (query: string) => {
  const parts = query.split(' ');

  const filteredParts = parts.filter(
    (part) => !STOPWORDS.some((sw) => part.includes(sw)),
  );

  if (filteredParts.length > 1) {
    return filteredParts.join(' ');
  }

  return query;
};

export {
  useFetchIngredient,
  useAutocompleteSearch,
  fetchIngredient,
  fetchProduct,
};
