import React from 'react';
import FabLabel from './FabLabel';
import { IonFabButton } from '@ionic/react';

interface IProps {
  label: string;
  color: string;
  children: React.ReactNode;
  onClick?: () => void;
  routerLink?: string;
  size: 'small';
}

const FabButton: React.FC<IProps> = ({ label, color, children, ...rest }) => {
  return (
    <span>
      {label && <FabLabel color={color}>{label}</FabLabel>}
      <IonFabButton color={color} {...rest}>
        {children}
      </IonFabButton>
    </span>
  );
};

export default FabButton;
